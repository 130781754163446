export default class Component {

    static components = [];

    constructor() {
        Component.components.push(this);
    }

    update(delta) {

    }
}