import './App.css';
import BasicThreeExample from './components/BasicThreeExample';
import { Auth0Provider } from '@auth0/auth0-react';
import AppBar from './components/AppBar';
import React, { useEffect } from 'react';
import config from './config';
import * as Sentry from '@sentry/react';
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';


const theme = createMuiTheme({
    palette: {
      primary: {
          main: "#757575"
      }
    }
});


function App()
{
    useEffect(() => {
        if(config.sentry.enabled){
            Sentry.init({
                // ...
            });
        }
    }, [])

    return (
        <div style={{height: '100%', width: "100%"}}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BasicThreeExample />
            </ThemeProvider>
        </div>
    );
}

export default App;
