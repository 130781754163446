import * as THREE from 'three';
import Clickable from "./Clickable";
import UnlitImageMaterial from "./UnlitMaterial";
import Hoverable from "./Hoverable";

export default class ModalTrigger {

    constructor(scene, onClick, src, width) {
        this.scene = scene;

        const geometry = new THREE.PlaneBufferGeometry(width, width);
        const material = new UnlitImageMaterial({src});
        const board = new THREE.Mesh(geometry, material);
        material.obj = board;
        this.board = board;
        this.scene.add(this.board);
        this.board.rotateY(90 * THREE.Math.DEG2RAD);
        this.board.scale.set(.33, .33, .33);
        const handleClick = (clickable) => {
            onClick(true);
        }
        new Clickable(this.board, handleClick)
        

        const mySpriteMaterial = new THREE.SpriteMaterial({
            map: new THREE.TextureLoader().load("assets/icons/click.png"),
            transparent: true,
        })

        const sprite = new THREE.Sprite(mySpriteMaterial);
        this.objectSprite = sprite;
        scene.add(sprite);
        sprite.scale.set(1.20, .40, .60);
        new Clickable(this.objectSprite, handleClick)

        // new Hoverable(this.objectSprite, () => {
        //     document.body.style.cursor = 'pointer';
        // });

        new Hoverable(this.objectSprite,
            () => {
                document.body.style.cursor = 'pointer';
                this.objectSprite.scale.set(1.40, .50, .60);
            },
            () => {
                this.objectSprite.scale.set(1.20, .40, .60);
            }
        );

    }

    setPosition = (x, y, z) => {
        // this.board.position.set(x, y, z);
        this.objectSprite.position.set(x, y, z);
    }

    setRotation = (x, y, z) => {
        // this.board.rotateY(y * THREE.Math.DEG2RAD);

    }

}
