import * as THREE from 'three';
import Clickable from "./Clickable";
// import {RecordEvent} from "../libs/AnalyticsLib";

export default class VideoViewer {

    static viewers = [];
    currentIndex = 0;

    constructor(scene, videoId, poster, width, height) {

        VideoViewer.viewers.push(this);

        this.id = videoId;
        this.scene = scene;
        this.width = width || 2.8;
        this.height = height || 1.55;
        const buttonSize = 0.2;

        this.loader = new THREE.TextureLoader();

        const geometry = new THREE.PlaneBufferGeometry(this.width, this.height);

        const board = new THREE.Mesh(geometry);
        this.board = board;
        scene.add(this.board);

        this.loader.load("assets/icons/ZoneVS-pause.png",
            (texture) => {
                const material = new THREE.MeshBasicMaterial({
                    map: texture
                });
                // const geometry = new THREE.PlaneBufferGeometry(buttonSize, buttonSize);
                const geometry = new THREE.CircleGeometry(buttonSize * .5, 32);

                const controlA = new THREE.Mesh(geometry, material);
                // controlA.position.x =-(this.width / 2) - (buttonSize / 2);
                // controlA.position.y =  -(buttonSize / 2);
                controlA.position.x = 0;
                controlA.position.y = -(this.height / 1.9) - (buttonSize / 2);


                controlA.position.z = -(-.025);
                this.board.add(controlA);
                new Clickable(controlA, this.pause)
                this.pauseButton = controlA;
                this.pauseButton.visible = false;
            }
        );

        this.loader.load("assets/icons/ZoneVS-play.png",
            (texture) => {
                const material = new THREE.MeshBasicMaterial({
                    map: texture
                });
                // const geometry = new THREE.PlaneBufferGeometry(buttonSize, buttonSize);
                const geometry = new THREE.CircleGeometry(buttonSize / 2, 32);
                const controlB = new THREE.Mesh(geometry, material);
                // controlB.position.x = -(this.width / 2) - (buttonSize / 2);
                controlB.position.x = 0;
                controlB.position.y = 0;

                controlB.position.z = -(-.025);
                this.board.add(controlB);
                new Clickable(controlB, this.play)
                this.playButton = controlB;
            }
        );

        const video = document.getElementById(videoId);
        // video.play();
        this.video = video;

        const texture = new THREE.VideoTexture(video);
        this.videoMaterial = new THREE.ShaderMaterial({
            fragmentShader: this.fragmentShader(),
            vertexShader: this.vertexShader(),
            uniforms: {tex: {type: 't', value: texture}}
        });
        this.board.material = this.videoMaterial;

        this.loader.load(poster,
            (texture) => {
                // const material = new THREE.MeshBasicMaterial({
                //     map: texture
                // });
                const material = new THREE.ShaderMaterial({
                    fragmentShader: this.fragmentShader(),
                    vertexShader: this.vertexShader(),
                    uniforms: {tex: {type: 't', value: texture}}
                });
                this.posterMaterial = material;
                this.board.material = material;
                this.board.material.needsUpdate = true;
            }
        );
    }

    setPosition = (x, y, z) => {
        this.board.position.set(x, y, z);
    }

    setRotation = (x, y, z) => {
        this.board.rotateY(y * THREE.Math.DEG2RAD);
        //this.board.rotation.setFromVector3(h, Math.PI/p, b);
        //this.board.rotation.setFromVector3(new THREE.Vector3 ( x, Math.PI/y , z) );
    }

    play = () => {
        VideoViewer.viewers.forEach((video) => {
            video.pause(true);
        })

        this.board.material = this.videoMaterial;

        this.video.play();
        this.playButton.visible = false;
        this.pauseButton.visible = true;

        // RecordEvent({
        //     type: "video-play",
        //     id: this.id
        // })
    }

    pause = (internal) => {
        this.video.pause();
        this.playButton.visible = true;
        this.pauseButton.visible = false;

        // if(!internal){
        //     RecordEvent({
        //         type: "video-pause",
        //         id: this.id
        //     })
        // }

    }

    vertexShader() {
        return `
            varying vec2 vUv;

            void main() {
                vUv = uv;

                vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
                gl_Position = projectionMatrix * modelViewPosition;
            }
        `
    }

    fragmentShader() {
        return `
            uniform sampler2D tex;
            varying vec2 vUv;

            void main() {
                gl_FragColor = texture2D(tex, vUv);
            }
        `
    }
}
