import * as THREE from 'three';
import Clickable from "./Clickable";

export default class ClickableMap {

    static currentPad;
    static pads = [];

    constructor(scene, controls) {
        this.controls = controls;
        this.rotateTo = null;

        const geometry = new THREE.CircleGeometry(1, 32);
        const material = new THREE.MeshBasicMaterial({color: 84154});
        const circle = new THREE.Mesh(geometry, material);
        circle.rotateX(-Math.PI / 2)
        scene.add(circle);

        this.object = circle;
        ClickableMap.pads.push(this);
        new Clickable(circle, this.handleClick)
    }

    set position(position) {
        this.object.position.set(position.x, position.y, position.z)
    }

    setRotation = (x, y, z) => {
        this.object.rotateY(x * THREE.Math.DEG2RAD);
    }

    get position() {
        return this.object.position;
    }

    handleClick = () => {
        ClickableMap.currentPad = this;
        this.controls.moveTo(new THREE.Vector3(this.object.position.x, this.object.position.y + 1.6, this.object.position.z), this.rotateTo);
    }
}