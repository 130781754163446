import * as THREE from 'three';
import {LoadingManager} from "three";

export default class Heightmap {
    vertexShader() {
        return `
            varying vec2 vUv;

            void main() {
                vUv = uv;

                vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
                gl_Position = projectionMatrix * modelViewPosition;
            }
        `
    }

    fragmentShader() {
        return `
            uniform sampler2D tex;
            uniform vec2 playerPos;

            uniform vec2 boundsMin; // min X/Y values
            uniform vec2 boundsMax; // max X/Y values

            varying vec2 vUv;

            float d1 = 0.05f;
            float d2 = 0.0125f;

            void main() {
                vec4 color = texture2D( tex, vUv );
                float x = (playerPos.x + abs(boundsMin.x)) / (boundsMax.x + abs(boundsMin.x));
                float y = (playerPos.y + abs(boundsMin.y)) / (boundsMax.y + abs(boundsMin.y));
                float dx = vUv.x - x;
                float dy = vUv.y - y;
                if (dx < d1 && dy < d1 && dx > -d1 && dy > -d1 && dx > d2 && dy > d2) {
                    color = vec4(1, 0, 0, 1);
                }
                gl_FragColor = color;
            }
        `
    }

    // constructor(scene, src, camera, hudScene) {
    constructor(scene, src, camera) {
        // toggle the hud here
        this.hudEnabled = false;
        this.mapEnabled = false;

        this.scene = scene;
        this.camera = camera;
        this.src = src;
        this.playerPos = new THREE.Vector2(0.5, 0.5);

        //xmin ymin???
        //xmax ymax???
        this.boundsMin = new THREE.Vector2(-45.0, -30.176);
        this.boundsMax = new THREE.Vector2(21.93, 11.455);

        //set to the res of heightmap tex
        this.renderTextureSize = new THREE.Vector2(2000, 1215);

        this.renderTexture = new THREE.WebGLRenderTarget(this.renderTextureSize.x, this.renderTextureSize.y, {
            minFilter: THREE.LinearFilter,
            magFilter: THREE.LinearFilter,
            format: THREE.RGBAFormat,
            type: THREE.FloatType
        });

        this.loader = new THREE.TextureLoader(LoadingManager.instance);

        const geometry = new THREE.PlaneBufferGeometry(2, 2);
        const material = new THREE.MeshBasicMaterial({color: "white"});

        const board = new THREE.Mesh(geometry, material);
        this.board = board;
        // hudScene.add(this.camera);
        this.camera.add(this.board);
        this.boardX = -2.75;
        this.boardY = 0.5;
        this.boardZ = -2;
        this.board.position.set(this.boardX, this.boardY, this.boardZ);

        const rtMaterial = new THREE.MeshBasicMaterial({color: "white", map: this.renderTexture});
        const rtBoard = new THREE.Mesh(geometry, rtMaterial);
        this.rtBoard = rtBoard;
        if (this.mapEnabled) {
            this.scene.add(this.rtBoard);
            this.rtBoard.position.set(3, 1.9, -9.4);
        }

        this.loader.load(src,
            (texture) => {
                this.texture = texture
                let uniforms = {
                    tex: {type: 't', value: texture},
                    playerPos: {type: 't', value: this.playerPos},
                    boundsMin: {type: 't', value: this.boundsMin},
                    boundsMax: {type: 't', value: this.boundsMax}
                }

                const material = new THREE.ShaderMaterial({
                    uniforms: uniforms,
                    fragmentShader: this.fragmentShader(),
                    vertexShader: this.vertexShader()
                });

                this.board.material = material;
                this.board.material.needsUpdate = true;
            }
        );

        this.orthoCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, -1000, 10000);
        this.orthoCamera.position.z = 100;

        this.mapScene = new THREE.Scene();
    }

    setPlayerPos(x, y) {
        this.playerPos.x = x;
        this.playerPos.y = y;
    }

    onMove(cameraPos, rot) {
//////////////////////////////////////////////////////////////////////////////////////
        //DEBUG
        console.log(`DEBUG setPosition(${cameraPos.x},${cameraPos.y},${cameraPos.z})`)
        console.log(`DEBUG           new THREE.Quaternion(${rot.x},${rot.y},${rot.z},${rot.w});`);
//////////////////////////////////////////////////////////////////////////////////////
        this.setPlayerPos(cameraPos.x, -cameraPos.z);
    }

    update(renderer) {
        if (this.texture) {

            renderer.setRenderTarget(this.renderTexture);
            renderer.clear();

            this.mapScene.add(this.board);
            this.board.position.set(0, 0, 1000);
            renderer.render(this.mapScene, this.orthoCamera);

            this.camera.add(this.board);
            this.board.position.set(this.boardX, this.boardY, this.boardZ);
            renderer.setRenderTarget(null);

            const read = new Float32Array(4);

            const x = (this.playerPos.x + Math.abs(this.boundsMin.x)) / (this.boundsMax.x + Math.abs(this.boundsMin.x)) * this.renderTextureSize.x;
            const y = (this.playerPos.y + Math.abs(this.boundsMin.y)) / (this.boundsMax.y + Math.abs(this.boundsMin.y)) * this.renderTextureSize.y;

            renderer.readRenderTargetPixels(this.renderTexture, x, y, 1, 1, read);
            let avg = (read[0] + read[1] + read[2]) / 3;
            let newHeight = 1.6 + (avg * 2);

            // use this log for tips to build heightmap and set min / max
            // console.log(`pos: ${this.playerPos.x} ${this.playerPos.y} rgb(${read[0]}, ${read[1]}, ${read[2]}) avg color: ${avg} newHeight: ${newHeight}`)
            return newHeight;
        } else {
            return 1.6;  // ground floor height
        }
    }
}
