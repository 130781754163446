import * as THREE from 'three';
import {BufferGeometryUtils} from 'three/examples/jsm/utils/BufferGeometryUtils';
import ThreeMeshUI from 'three-mesh-ui'
// import Clickable from "./Clickable";
// import UnlitImageMaterial from "./UnlitMaterial";
import ImageViewer from "./ImageViewer";
import Hoverable from "./Hoverable";

// import {RecordEvent} from "../libs/AnalyticsLib";

function distanceBetween(point1, point2) {
    return Math.sqrt(
        Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2)
    );
}

function angleBetween(point1, point2) {
    return Math.atan2(point2.x - point1.x, point2.y - point1.y);
}

/* A function to return random number from min to max */
// We're not using it - I think it is lft over from the demo'
// function getRandomInt(min, max) {
//     return Math.floor(Math.random() * (max - min + 1)) + min;
// }

export default class Whiteboard2 {

    raycaster = new THREE.Raycaster();
    isDrawing = false;
    width = 3;
    height = 3;

    mode = "drawing";

    //Drawing
    brushGap = 0.015
    brushSize = 0.0075;
    meshes = [];
    mergedGeometries = [];
    geometries = [];
    geometry = new THREE.BufferGeometry();

    history = []

    prevColor = new THREE.Color("black")
    color = new THREE.Color("black")


    //Text
    textContainers = [];
    textDialogOpen = false;

    currentText = undefined;
    currentTextContainer = undefined;

    history = []

    constructor(scene, images, camera, renderer, width, height, onDrawStart, onDrawStop, onEnterTextMode, onLeaveTextMode) {

        this.scene = scene;
        this.images = images;
        this.camera = camera;
        this.renderer = renderer;
        this.onDrawStart = onDrawStart;
        this.onDrawStop = onDrawStop;


        this.onCreateText = onEnterTextMode;
        this.onCreateTextLeave = onLeaveTextMode;

        this.width = width || 3;
        this.height = height || 3;

        this.loader = new THREE.TextureLoader();

        this.imageViewer = new ImageViewer(scene, images, this.width * 0.96, this.height, true);
        this.imageViewer.setPosition(0, 0, -0.01);
        this.board = this.imageViewer.board;


        this.renderer.domElement.addEventListener("pointermove", this.onMouseMove, false);

        this.renderer.domElement.addEventListener("pointerdown", e => {
                const point = this.getMousePoint(e);
                if (point) {
                    switch (this.mode) {

                        case 'drawing': {
                            this.isDrawing = true;
                            this.lastPoint = point;
                            this.newDrawingBlock = true;
                            this.onDrawStart();
                            break;
                        }
                        case 'text': {
                            const w = this.width - point.x - (this.width / 2);
                            const h = this.height + point.y - (this.height / 2);
                            const x = point.x + (w / 2);
                            const y = point.y - (h / 2);

                            const container = new ThreeMeshUI.Block({
                                width: w,
                                height: h,
                                // padding: 0.2,

                                //trying again to make the text right justified from click
                                //Maybe calculate tota l size of text block then transform???
                                // justifyContent: 'center',
                                alignContent: 'left',

                                fontFamily: './assets/fonts/Roboto/Roboto-msdf.json',
                                fontTexture: './assets/fonts/Roboto/Roboto-msdf.png',
                                backgroundOpacity: 0.0
                            });
                            this.currentTextContainer = container;
                            this.textContainers.push(container);
                            //

                            //https://github.com/felixmariotto/three-mesh-ui/blob/master/examples/inline_block.js
                            const text = new ThreeMeshUI.Text({
                                content: "",
                                width: w,
                                height: h,

                                fontSize: 0.05,
                                fontColor: new THREE.Color("black"),

                            });
                            this.currentText = text;

                            container.add(text);
                            //trying again to make the text right justified from click
                            //Maybe calculate total size of text block then transform???
                            container.position.set(x, y, point.z);
                            // container.position.set(point.x, point.y-0.11, point.z);

                            // scene is a THREE.Scene (see three.js)
                            this.board.add(container);
                            //trying again to make the text right justified from click
                            //Maybe calculate total size of text block then transform???
                            // container.position.set(container.position.x+0.6, container.position.y-.4, container.position.z + 0.01)
                            // container.position.set(container.position.x, container.position.y-0.11, container.position.z + 0.01)

                            this.onCreateText();
                            break;
                        }
                        default: {
                        }
                    }
                }
            },
            false
        );

        this.renderer.domElement.addEventListener("pointerup", () => {
                this.isDrawing = false;
                this.onDrawStop();
                console.log(this.meshes)
            },
            false
        );

        //Screenshot stuff

        this.screenshotCam =
            new THREE.OrthographicCamera(
                this.width * 0.96 / -2,
                this.width * 0.96 / 2,
                this.height / 2,
                this.height / -2,
                0.01,
                0.5
            );

        this.cameraHelper = new THREE.CameraHelper(this.screenshotCam);
        // scene.add( this.cameraHelper );

        const onWindowResize = () => {

            const cam = this.screenshotCam;

            cam.left = (this.width) / -2;
            cam.right = (this.width) / 2;

            cam.top = (this.height) / 2;
            cam.bottom = (this.height) / -2;

            this.screenshotCam.updateProjectionMatrix();
        }

        window.addEventListener('resize', onWindowResize, false);

        this.board.add(this.screenshotCam);
        this.screenshotCam.position.z = 0.15

        new Hoverable(this.board, () => {
            if (this.mode === 'drawing') {
                document.body.style.cursor = 'crosshair';
            } else if (this.mode === 'text') {
                document.body.style.cursor = 'text';
            }
        });
    }

    undo = () => {
        const history = this.history.pop();
        if (!history) return;

        switch (history.type) {
            case "text": {
                const text = this.textContainers.pop()
                this.board.remove(text);
                break;
            }
            case "drawing": {
                const mesh = this.meshes.pop()
                this.scene.remove(mesh);
                break
            }
            //NOT SURE WHY THIS NEEDED OR IF CORRECT SOLUTION
            // default: {
            // }
        }

    }

    getMouseX = (event) => {
        return (event.offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
    }

    getMouseY = (event) => {
        return -(event.offsetY / this.renderer.domElement.clientHeight) * 2 + 1;
    }

    getMousePoint = (e) => {
        let x = this.getMouseX(e);
        let y = this.getMouseY(e);

        this.raycaster.setFromCamera(new THREE.Vector2(x, y), this.camera);
        const intersects = this.raycaster.intersectObject(this.board);

        if(intersects.length === 0) return;

        const intersection = intersects[0];

        if (intersection && intersection.uv && intersection.distance < 5) {
            return {
                x: intersects[0].uv.x * this.width - this.width / 2,
                y: intersects[0].uv.y * this.height - this.height / 2
            };
        }
    }

    onMouseMove = (event) => {
        if (!this.isDrawing) return;

        const currentPoint = this.getMousePoint(event);

        if (!currentPoint || !currentPoint.x || !currentPoint.y) return;
        if (!this.lastPoint || !this.lastPoint.x || !this.lastPoint.y) return;

        const brushGap = this.brushSize / 2;

        var dist = distanceBetween(this.lastPoint, currentPoint);
        if (dist < brushGap) return;

        var angle = angleBetween(this.lastPoint, currentPoint);

        const geometries = [];
        const helperObject = new THREE.Object3D();
        this.board.add(helperObject);

        for (var i = 0; i < dist; i += brushGap) {

            let x = this.lastPoint.x + Math.sin(angle) * i;
            let y = this.lastPoint.y + Math.cos(angle) * i;

            const position = new THREE.Vector3(x, y, 0);
            helperObject.position.set(position.x, position.y, 0.01)
            helperObject.scale.setScalar(this.brushSize);
            helperObject.updateMatrixWorld(true, true);

            //migth look smoother  as a circle
            const geometry = new THREE.PlaneBufferGeometry(1, 1);
            geometry.applyMatrix4(helperObject.matrixWorld);
            geometries.push(geometry);
        }

        if (this.newDrawingBlock || this.meshes.length === 0 || this.geometries.length >= 5000) {
            this.newDrawingBlock = false;

            const mesh = new THREE.Mesh();
            mesh.material = new THREE.MeshBasicMaterial({color: this.color});

            this.scene.add(mesh);
            this.meshes.push(mesh)
            this.history.push({
                type: "drawing",
                mesh
            })

            const geometry = new THREE.BufferGeometry();
            this.mergedGeometries.push(geometry);

            this.geometries = [];
        }

        this.prevColor = this.color;

        this.geometries = [...this.geometries, ...geometries];

        const mesh = this.meshes[this.meshes.length - 1];
        const geometry = BufferGeometryUtils.mergeBufferGeometries(this.geometries, false);

        mesh.geometry = geometry;

        this.mergedGeometries[this.mergedGeometries.length - 1] = geometry
        this.lastPoint = currentPoint;

    }

    setPosition = (x, y, z) => {
        this.board.position.set(x, y, z);
    }

    setRotation = (x, y, z) => {
        this.board.rotateY(y * THREE.Math.DEG2RAD);
        ;
    }

    clear() {
        this.meshes.forEach((mesh) => {
            this.scene.remove(mesh);
        });

        this.textContainers.forEach((container) => {
            this.board.remove(container);
        })

        this.textContainers = [];
        this.mergedGeometries = []
        this.meshes = []
        this.geometry = []
    }

    takeScreenshot = () => {
        const multiplier = 1000;
        this.renderer.setSize(this.width * multiplier, this.height * multiplier);
        this.renderer.render(this.scene, this.screenshotCam);
        const dataUrl = this.renderer.domElement.toDataURL("image/png");
        const link = document.createElement('a');

        link.setAttribute('href', dataUrl);
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `whiteboard-${new Date().toLocaleTimeString()}.png`);

        link.click();
        this.renderer.setSize(window.innerWidth, window.innerHeight)

    }

    takeScreenshotMainCamera = () => {
        this.renderer.render(this.scene, this.camera);
        const dataUrl = this.renderer.domElement.toDataURL("image/png");
        const link = document.createElement('a');
        link.setAttribute('href', dataUrl);
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `whiteboard-${new Date().toLocaleTimeString()}.png`);
        link.click();
    }

    enableDrawingMode = () => {
        this.mode = "drawing";
    }

    enableTextMode = () => {
        this.mode = "text";
        console.log("Text mode enabled")
    }

    setCurrentTextValue = (value) => {
        this.currentText.set({content: value});

        this.history.push({
            type: "text",
            text: this.currentTextContainer
        })

        this.currentText = undefined;
        this.currentTextContainer = undefined;

        //Set drawing mode when the text has been confirmed.
        this.enableDrawingMode();
        this.onCreateTextLeave();
    }

    deleteCurrentTextField = () => {
        this.board.remove(this.currentTextContainer);
        this.currentText = undefined;
        this.currentTextContainer = undefined;
    }

    update = () => {
        ThreeMeshUI.update();
        this.cameraHelper.update()
    }

    rotateY(angle) {
        this.board.rotateY(angle);
    }
}