import * as THREE from 'three';
import Clickable from "./Clickable";
import Hoverable from "./Hoverable";
// import {RecordEvent} from "../libs/AnalyticsLib";


export default class MovePad {

    static currentPad;
    static pads = [];
    static heroPads = [];

    constructor(hero, scene, controls, xRot, scale, colour, spriteImage) {
        this.hero = hero;
        this.controls = controls;
        this.rotateTo = null;
        this.xRot = xRot || -90.0;
        this.scale = scale || 0.7;
        this.colour = colour || 84154;
        this.spriteImage = spriteImage || "assets/icons/AZlogo.png";

        //make all these unlit
        //create a basic flat colour geometry pad
        const geometry = new THREE.CircleGeometry(this.scale, 32);
        const material = new THREE.MeshBasicMaterial({
            color: ("rgb(0, 127, 255)"),
            opacity: 0.15,
            transparent: true,
        });
        const circle = new THREE.Mesh(geometry, material);
        circle.rotateX(this.xRot * THREE.Math.DEG2RAD);
        scene.add(circle);
        this.object = circle;
        MovePad.pads.push(this);
        new Clickable(circle, this.handleClick)

        if(this.hero){
            MovePad.heroPads.push(this);
        }

        // this.loader.load(src,
        //     (texture) => {
        //         // const material = new THREE.MeshBasicMaterial({map: texture});
        //         const material = new THREE.ShaderMaterial({
        //             fragmentShader: this.fragmentShader(),
        //             vertexShader: this.vertexShader(),
        //             uniforms: {tex: {type: 't', value: texture}}
        //         });
        //         this.board.material = material;
        //         this.board.material.needsUpdate = true;
        //     }
        // );
        // this.loader = new THREE.TextureLoader();

        //create a textured sprite:
        const mySpriteMaterial = new THREE.SpriteMaterial({
            map: new THREE.TextureLoader().load(this.spriteImage),
            // map: new THREE.TextureLoader().load("assets/icons/AZlogo.png"),
            transparent: true,
        })
        const sprite = new THREE.Sprite(mySpriteMaterial);
        this.objectSprite = sprite;
        scene.add(sprite);
        sprite.scale.set(.6, .6, .6);
        new Clickable(sprite, this.handleClick)

        new Hoverable(this.object, 
            () => {
                this.objectSprite.scale.setScalar(0.66)
                document.body.style.cursor = 'pointer';
            },
            () => {
                this.objectSprite.scale.setScalar(0.6);
            }
        );
        new Hoverable(this.objectSprite, 
            () => {
                this.objectSprite.scale.setScalar(0.66)
                document.body.style.cursor = 'pointer';
            },
            () => {
                this.objectSprite.scale.setScalar(0.6);
            }
        );

    }

    set position(position) {
        this.object.position.set(position.x, position.y, position.z)
        this.objectSprite.position.set(position.x, position.y+.35, position.z)
    }

    setRotation = (x, y, z) => {
        this.object.rotateZ(45 * THREE.Math.DEG2RAD);
    }

    get position() {
        return this.object.position;
    }

    handleClick = () => {
        MovePad.currentPad = this;
        const myParameter = 42;
        // console.log(`DEBUG myParameter in MovePad.js = ${myParameter}`);

        //I wanted to see if I could pass a parameter to moveto to tell it what viewpoint to choose but it's not needed
        // this.controls.moveTo(new THREE.Vector3(this.object.position.x, this.object.position.y + 1.6, this.object.position.z), this.rotateTo, myParameter);
        //this.controls.moveTo(new THREE.Vector3(this.object.position.x, this.object.position.y + 1.6, this.object.position.z), this.rotateTo, this.rotateToViewSouth, this.rotateToViewEast, this.rotateToViewWest );

        if (!this.offsetPosition) {
            this.controls.moveTo(new THREE.Vector3(this.object.position.x, this.object.position.y + 1.6, this.object.position.z), this.rotateTo, this.rotateToViewSouth, this.rotateToViewEast, this.rotateToViewWest );
        }
        else {
            this.controls.moveTo(new THREE.Vector3(this.offsetPosition.x, this.offsetPosition.y + 1.6, this.offsetPosition.z), this.rotateTo, this.rotateToViewSouth, this.rotateToViewEast, this.rotateToViewWest);
        }
    }

    vertexShader() {
        return `
            varying vec2 vUv;

            void main() {
                vUv = uv;

                vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
                gl_Position = projectionMatrix * modelViewPosition;
            }
        `
    }

    fragmentShader() {
        return `
            uniform sampler2D tex;
            varying vec2 vUv;

            void main() {
                gl_FragColor = texture2D(tex, vUv);
            }
        `
    }
}