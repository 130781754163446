import {detect} from "detect-browser";

export function IsBrowserCompatible(){
    const browser = detect();

    let compatible = true;

    // handle the case where we don't detect the browser
    if (browser) {
        console.log(browser.name);
        console.log(browser.version);
        console.log(browser.os);

        //fake error to test sentry
        // throw new Error("break")

//WHY?
// eslint-disable-next-line
        switch (browser.name){
            //BAD - alt enter create missing states
            // case "chrome":
            // case "safari":
            // case "ie":
            case "firefox":
            {
                compatible = false;
                break;
            }
            case "ie":
            {
                compatible = false;
                break;
            }
        }

//WHY?
// eslint-disable-next-line
        switch (browser.os){
            //BAD - alt enter create missing states
            // case "iOS":
            // case "Android OS":
            case "Windows Mobile":
            // case "Windows 10":
            {
                compatible = false;
                break;
            }
        }
    }
    return compatible;
}

