const dev = {
    auth0: {
        domain: "pestex.eu.auth0.com",
        clientId: "JFVVaGWPlQE6Go86pB9y9LuIHEWoNTKJ"
    },
    sentry: {
        enabled: false
    }
}

const prod = {
    auth0: {
        domain: "pestex.eu.auth0.com",
        clientId: "JFVVaGWPlQE6Go86pB9y9LuIHEWoNTKJ"
    },
    sentry: {
        enabled: true
    }
}

// Default to dev if not set
const config = (function ()
{
  switch (process.env.REACT_APP_STAGE)
  {
    case "prod":
      return prod;
    case "dev":
      return dev;
    default:
      return dev;
  }
})(dev);

export default {
  ...config
};
