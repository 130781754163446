import React, {useEffect, useRef, useState} from "react";
import * as THREE from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import Clickable from "../three-components/Clickable";
import Draggable from "../three-components/Draggable";
import Hoverable from "../three-components/Hoverable";
import MovePad from "../three-components/MovePad";
import LookControlsV2 from "../three-components/LookControlsV2";
import HDREnvironment from "../three-components/HDREnvironment";
import {useAuth0} from "@auth0/auth0-react";
import Whiteboard from "../three-components/Whiteboard2";
import WhiteboardControls from "../three-components/WhiteboardControls";
import ImageViewer from "../three-components/ImageViewer";
import ClickableMap from "../three-components/ClickableMap";
import Heightmap from "../three-components/heightmap";
import ModalTrigger from "../three-components/modal-trigger";
import ModalTriggerPDF from "../three-components/modal-triggerPDF";
import PostItNote from "../three-components/PostItNote";
import TextFieldDialog from "../components/TextFieldDialog";
import ImageModal from "../components/ImageModal";
import ImageModal_01 from "../components/ImageModal_01";
import ImageModal_02 from "../components/ImageModal_02";
import ImageModal_03 from "../components/ImageModal_03";
import ImageModal_04 from "../components/ImageModal_04";
import ImageModal_05 from "../components/ImageModal_05";
import ImageModal_06 from "../components/ImageModal_06";
import ImageModal_07 from "../components/ImageModal_08";
import ImageModal_08 from "../components/ImageModal_08";
import ImageModal_09 from "../components/ImageModal_09";
import MenuIcon from '@material-ui/icons/Menu';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
// import GavelIcon from '@material-ui/icons/Gavel';
import WelcomeModal from "../components/WelcomeModal";
import VideoViewer from '../three-components/VideoViewer'
import {IsBrowserCompatible} from "../libs/BrowserLib";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {Box, Button, DialogActions, IconButton, Typography} from "@material-ui/core";
// import {useIntercom} from "react-use-intercom";
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import * as Stats from 'stats.js'
import LoadingManager from "../utilities/LoadingManager";
import ToolBar from "./ToolBar";
import Component from "../three-components/Component";
import ObjectRotator from "../three-components/ObjectRotator";
import UnlitImageMaterial from "../three-components/UnlitMaterial";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Screenshot from "../three-components/Screenshot";

const RAYCAST_EXCLUDE_LAYER = 31;

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////DEFINE PDF BOARDS////////////////////////////////////////////////
const PDFBoards = [
    {
        imagePDF: "assets/icons/whiteboard_big.png",
        pdf:   "assets/PDF/ENTRANCE/E-download1.pdf",
        positionX:       -17.5,
        positionY:     1.35,
        positionZ:     20.0,
        rotationY: -90
    },
    {
        imagePDF: "assets/icons/whiteboard_big.png",
        pdf:   "assets/PDF/ENTRANCE/E-download2.pdf",
        positionX:       -18.5,
        positionY:     1.35,
        positionZ:     20.0,
        rotationY: -90
    },
    {
        imagePDF: "assets/icons/whiteboard_big.png",
        pdf:   "assets/PDF/IDEA/I-download1.pdf",
        positionX:       5.0,
        positionY:     1.35,
        positionZ:     -6.5,
        rotationY: -90
    },
]
///////////////////////////////////////////END OF DEFINE PDF BOARDS////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////BEGIN MAIN THREE.JS//////////////////////////////////////////////////
const BasicThreeExample = () => {

    //FULLSCREEN
    const handle = useFullScreenHandle();
    const {isLoading, isAuthenticated} = useAuth0();
    const [controls, setControls] = useState();
    const mountRef = useRef(null);
    const [initialised, setInitialised] = useState(false);
    const [whiteboard, setWhiteboard] = useState();
    const [showLoadingScreen, setShowLoadingScreen] = useState(true);

    const [menuOpen, setMenuOpen] = useState(false);

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////AUTH0/////////////////////////////////////////////////////
    //currently not used
    // useEffect(() => {
    //     console.log('Auth change')
    //     if(!controls) return;
    //     if(isLoading) controls.enabled = false;
    //     controls.enabled = isAuthenticated;
    // }, [isAuthenticated])
///////////////////////////////////////////////////////AUTH0 END//////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////Thing to hook into React??///////////////////////////////////////////
    //A bit like "Awake"
    useEffect(() => {
        if (!mountRef.current || initialised) return;

        setInitialised(true);

        const mouse = new THREE.Vector2();

        //Setup three7
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        camera.layers.enableAll();
        const renderer = new THREE.WebGLRenderer({antialias: true});

        //Create a loading manager
        new LoadingManager(
            () => setShowLoadingScreen(false)
        );

        //GEORGES DEBUG DEVICE?
        // const hudScene = new THREE.Scene();

        renderer.setSize(window.innerWidth, window.innerHeight);
        mountRef.current.appendChild(renderer.domElement);

        const clock = new THREE.Clock();
        const raycaster = new THREE.Raycaster();
        raycaster.layers.disable(RAYCAST_EXCLUDE_LAYER);

////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////LIGHTING START////////////////////////////////////////////////
        //TODO *** MAKE all building parts emmissive and remove lighting
        //https://www.donmccurdy.com/2020/06/17/color-management-in-threejs/
        // Added this to enable PBR materials - might need tweaking
        // Sketchfab has richer colours, generally more accurate

        // renderer.toneMapping = THREE.NoToneMapping
        renderer.toneMapping = THREE.LinearToneMapping//USED
        // renderer.toneMapping = THREE.ReinhardToneMapping
        // renderer.toneMapping = THREE.CineonToneMapping
        // renderer.toneMapping = THREE.ACESFilmicToneMapping
        renderer.toneMappingExposure = 1.4;//USED
        // renderer.outputEncoding = THREE.LinearEncoding;//Default
        renderer.outputEncoding = THREE.sRGBEncoding;//USED
        // renderer.outputEncoding = THREE.GammaEncoding;

        //new Screenshot component
        new Screenshot(scene, camera, renderer)

        //Set up the HDR texture environment
        new HDREnvironment(scene, renderer, "assets/AstraZeneca_Entrance.hdr");

        const ambientLight = new THREE.AmbientLight( 0xffffff , 0.5 ); // soft white light
        scene.add( ambientLight );

        // const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 10.6);
        // hemiLight.color.setHSL(0.6, 1, 0.6);
        // hemiLight.groundColor.setHSL(0.095, 1, 0.75);
        // hemiLight.position.set(0, 50, 0);
        // scene.add(hemiLight);
        // const hemiLightHelper = new THREE.HemisphereLightHelper(hemiLight, 10);
        // scene.add(hemiLightHelper);

        // const dirLight = new THREE.DirectionalLight(0xffffff, 1);
        // dirLight.color.setHSL(0.1, 1, 0.95);
        // dirLight.position.set(-1, 1.75, 1);
        // dirLight.position.multiplyScalar(30);
        // scene.add(dirLight);
        // dirLight.castShadow = true;
        // dirLight.shadow.mapSize.width = 2048;
        // dirLight.shadow.mapSize.height = 2048;
        // const d = 50;
        // dirLight.shadow.camera.left = -d;
        // dirLight.shadow.camera.right = d;
        // dirLight.shadow.camera.top = d;
        // dirLight.shadow.camera.bottom = -d;
        // dirLight.shadow.camera.far = 3500;
        // dirLight.shadow.bias = -0.0001;
        // const dirLightHelper = new THREE.DirectionalLightHelper(dirLight, 10);
        // scene.add(dirLightHelper);


//////////////////////////////////////////LIGHTING END//////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////GEORGE'S HEIGHTMAP////////////////////////////////////////////
        const heightmap = new Heightmap(
            scene,
            "assets/images/heightmap3.png",
            camera,
            // hudscene is turned off here and in component - it is to help define map area
            // hudScene
        );
///////////////////////////////////END OF GEORGE'S HEIGHTMAP////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////PDF BOARDS//////////////////////////////////////////////
        PDFBoards.forEach((board) => {
            const trigger = new ModalTriggerPDF(
                scene,
                () => window.open(board.pdf, "_blank"),
                board.imagePDF
            );
            trigger.setPosition(board.positionX, board.positionY, board.positionZ);
            trigger.setRotation(board.rotationY)
        });
////////////////////////////////////////////END PDF BOARDS//////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////CLICKABLE MODAL POPUPS////////////////////////////////////////////
        const modalTriggerSpeaker01 = new ModalTrigger(scene, setImageModalOpen, "/assets/icons/click.png", .5);
        modalTriggerSpeaker01.setPosition(-15., 2.66, 6.95);
        modalTriggerSpeaker01.setRotation(0, -90, 0);
// ///////////////////////
        const modalTriggerHallwayWest01a = new ModalTrigger(scene, setImageModalOpen_01a, "/assets/icons/click.png", .5);
        modalTriggerHallwayWest01a.setPosition(-21.55, 2.12, 6.2);
        modalTriggerHallwayWest01a.setRotation(0, 0, 0);

        const modalTriggerHallwayWest01b = new ModalTrigger(scene, setImageModalOpen_01b, "/assets/icons/click.png", .5);
        modalTriggerHallwayWest01b.setPosition(-21.55, 1.58, 6.2);
        modalTriggerHallwayWest01b.setRotation(0, 0, 0);
/////////////////////////
        const modalTriggerHallwayWest02a = new ModalTrigger(scene, setImageModalOpen_02a, "/assets/icons/click.png", .5);
        modalTriggerHallwayWest02a.setPosition(-21.55, 2.0, 3.40);
        modalTriggerHallwayWest02a.setRotation(0, 0, 0);

        const modalTriggerHallwayWest02b = new ModalTrigger(scene, setImageModalOpen_02b, "/assets/icons/click.png", .5);
        modalTriggerHallwayWest02b.setPosition(-21.55, 1.45, 3.40);
        modalTriggerHallwayWest02b.setRotation(0, 0, 0);
//////////////////////////////////////////////////////
        const modalTriggerHallwayWest04a = new ModalTrigger(scene, setImageModalOpen_04a, "/assets/icons/click.png", .5);
        modalTriggerHallwayWest04a.setPosition(-21.55, 2.05, -2.35);
        modalTriggerHallwayWest04a.setRotation(0, 0, 0);

        const modalTriggerHallwayWest04b = new ModalTrigger(scene, setImageModalOpen_04b, "/assets/icons/click.png", .5);
        modalTriggerHallwayWest04b.setPosition(-21.55, 1.6, -2.35);
        modalTriggerHallwayWest04b.setRotation(0, 0, 0);
//////////////////////////////////////////////
        const modalTriggerHallwayWest06b = new ModalTrigger(scene, setImageModalOpen_06b, "/assets/icons/click.png", .5);
        modalTriggerHallwayWest06b.setPosition(-21.1, 1.45, -10.45);
        modalTriggerHallwayWest06b.setRotation(0,-90,0);
//////////////////////////////////////////////
        const modalTriggerHallwayWest08a = new ModalTrigger(scene, setImageModalOpen_08a, "/assets/icons/click.png", .5);
        modalTriggerHallwayWest08a.setPosition(-15.1, 1.62, -4.55);
        modalTriggerHallwayWest08a.setRotation(0,-180,0);

        const modalTriggerHallwayWest08b = new ModalTrigger(scene, setImageModalOpen_08b, "/assets/icons/click.png", .5);
        modalTriggerHallwayWest08b.setPosition(-15.1, 1.06, -4.55);
        modalTriggerHallwayWest08b.setRotation(0,-180,0);
        //////////////////////////////////////////////

//         const modalTriggerHallwayWest06a = new ModalTrigger(scene, setImageModalOpen_08a, "/assets/icons/click.png", .5);
//         modalTriggerHallwayWest06a.setPosition(-21.55, 2.1, -2.35);
//         modalTriggerHallwayWest06a.setRotation(0, 0, 0);
// //
//         const modalTriggerHallwayWest08a = new ModalTrigger(scene, setImageModalOpen_08a, "/assets/icons/click.png", .5);
//         modalTriggerHallwayWest08a.setPosition(-21.55, 1.9, -5.5);
//         modalTriggerHallwayWest08a.setRotation(0, 0, 0);
///////////////////////////////////////////////////////
//         const modalTriggerHallwayWest08b = new ModalTrigger(scene, setImageModalOpen_08b, "/assets/icons/click.png", .5);
//         modalTriggerHallwayWest08b.setPosition(-21.55, 1.2, -5.5);
//         modalTriggerHallwayWest08b.setRotation(0, 0, 0);


// ////////////////////////////////////////////////////////////////
//     const modalTriggerHallwayNorth06 = new ModalTrigger(scene, setImageModalOpen_09, "/assets/icons/ZoneVS-teleport.png",.5);
//     modalTriggerHallwayNorth06.setPosition(-20.75, 2.35, -10.4);
//     modalTriggerHallwayNorth06.setRotation(0,-90,0);
// ////////////////////////////////////////////////////////////////
//     const modalTriggerHallwayEast07 = new ModalTrigger(scene, setImageModalOpen, "/assets/icons/ZoneVS-teleport.png",.5);
//     modalTriggerHallwayEast07.setPosition(-15.1, 2.35, -7.7);
//     modalTriggerHallwayEast07.setRotation(0,-180,0);
//
//     const modalTriggerHallwayEast08 = new ModalTrigger(scene, setImageModalOpen, "/assets/icons/ZoneVS-teleport.png",.5);
//     modalTriggerHallwayEast08.setPosition(-15.1, 2.35, -4.8);
//     modalTriggerHallwayEast08.setRotation(0,-180,0);
//
//     const modalTriggerHallwayEast09 = new ModalTrigger(scene, setImageModalOpen, "/assets/icons/ZoneVS-teleport.png",.5);
//     modalTriggerHallwayEast09.setPosition(-15.1, 2.35, -1.60);
//     modalTriggerHallwayEast09.setRotation(0,-180,0);
//
//     const modalTriggerHallwayEast10 = new ModalTrigger(scene, setImageModalOpen, "/assets/icons/ZoneVS-teleport.png",.5);
//     modalTriggerHallwayEast10.setPosition(-15.1, 2.35, 3.7);
//     modalTriggerHallwayEast10.setRotation(0,-180,0);
////////////////////////////////////END CLICKABLE MODAL POPUPS//////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////ADD CONTROLS////////////////////////////////////////////////
        //Add Controls
        const controls = new LookControlsV2(camera, renderer, scene);
        //SET START POSITION
        controls.moveTo(new THREE.Vector3(-15.097923577731548, 1.6, 17.96742758106487))//production start point

        // controls.moveTo(new THREE.Vector3(-0.03665007492927767, 1.6, -6.2827094072878875))
        // controls.moveTo(new THREE.Vector3( -40.52692200124051,3.599999394624065,14.615600299794265))
        // controls.moveTo(new THREE.Vector3(-14.9, 1.6, 17.8))
        controls.enabled = true;
        setControls(controls);
        controls.postMove = (pos, rot) => heightmap.onMove(pos, rot);
////////////////////////////////////////END ADD CONTROLS////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////LOAD 3D MODELS////////////////////////////////////////////////
        //Add room
        const loader = new GLTFLoader(LoadingManager.instance);

        //Load room
        loader.load(
            "assets/AstraZeneca_Ground_Floor_V01_pillars.glb",
            function (gltf) {
                const room = gltf.scene;
                room.castShadow = true;
                room.receiveShadow = true;
                scene.add(room);

                //Currently sets all objects in this GLB to exclusion layer
                // room.traverse((x) => {
                //     x.layers.disableAll()
                //     x.layers.enable(RAYCAST_EXCLUDE_LAYER);
                // })

                room.position.set(-3, 1.8, -3);
            }
        );

        loader.load(
            "assets/AstraZeneca_Ground_Floor_V01_pillars_glass.glb",
            function (gltf) {
                const glass = gltf.scene;
                glass.castShadow = true;
                glass.receiveShadow = true;
                scene.add(glass);

                // Currently sets all objects in this GLB to exclusion layer
                glass.traverse((x) => {
                    x.layers.disableAll()
                    x.layers.enable(RAYCAST_EXCLUDE_LAYER);
                })

                glass.position.set(-3, 1.8, -3);
            }
        );
        // this glb = immune to raycast

        // Material is noticeably different to model on sketchfab - check lights+gamma/linear etc
        // loader.load(
        //     "assets/models/gltf/scene.gltf",
        //     function (gltf) {
        //       const room = gltf.scene;
        //       room.castShadow = true;
        //       room.receiveShadow = true;
        //       scene.add(room);
        //       room.scale.set(.5,.5,.5);
        //       room.position.set(-1.1, -.575, -4);
        //       room.rotateY(225 * THREE.Math.DEG2RAD);
        //       room.visible = true;
        //
        //     }
        // );

        // loader.load(
        //     "assets/models/gltf/club_chair/scene.gltf",
        //     function (gltf) {
        //       const chair = gltf.scene;
        //       chair.castShadow = true;
        //       chair.receiveShadow = true;
        //       scene.add(chair);
        //       chair.position.set(-16.0,0.5, 15.0);
        //       chair.scale.setScalar(0.05);
        //       // chair.rotateY(-Math.PI / 2);
        //       // new ObjectRotator(chair, .5)
        //     }
        // );

        // loader.load(
        //     "assets/models/gltf/freddie_single_camera_speedscan/scene.gltf",
        //     function (gltf) {
        //       const freddie = gltf.scene;
        //       freddie.castShadow = true;
        //       freddie.receiveShadow = true;
        //       scene.add(freddie);
        //       freddie.position.set(-14.2,2.5, 15.0);
        //       freddie.scale.setScalar(0.1);
        //       // chair.rotateY(-Math.PI / 2);
        //       // new ObjectRotator(chair, .5)
        //     }
        // );

        //Load Kinetic sculpture
        loader.load(
            "assets/models/entranceSculpture01.glb",
            function (gltf) {
                const entranceSculpture01 = gltf.scene;
                // entranceSculpture01.castShadow = true;
                // entranceSculpture01.receiveShadow = true;
                scene.add(entranceSculpture01);
                entranceSculpture01.position.set(-15.4, .2, 13.0);
                entranceSculpture01.scale.setScalar(1.0);
                entranceSculpture01.rotateY(-Math.PI / 2);

                new ObjectRotator(entranceSculpture01, .5)
            }
        );

        //Load INFO sculpture
        loader.load(
            "assets/models/entranceInfoSculpture.glb",
            function (gltf) {
                const entranceInfoSculpture = gltf.scene;
                // entranceInfoSculpture.castShadow = true;
                // entranceInfoSculpture.receiveShadow = true;
                scene.add(entranceInfoSculpture);
                entranceInfoSculpture.position.set(-16.14, 1.7, 23.35);
                entranceInfoSculpture.scale.setScalar(1.0);
                entranceInfoSculpture.rotateY(-Math.PI / 2);

                new ObjectRotator(entranceInfoSculpture, .5)
            }
        );

        //Load INFO sculpture
        loader.load(
            "assets/models/entranceInfoSculpture.glb",
            function (gltf) {
                const entranceInfoSculpture = gltf.scene;
                // entranceInfoSculpture.castShadow = true;
                // entranceInfoSculpture.receiveShadow = true;
                scene.add(entranceInfoSculpture);
                entranceInfoSculpture.position.set(-27.0, 1.7, 29.4);
                entranceInfoSculpture.scale.setScalar(1.0);
                entranceInfoSculpture.rotateY(-Math.PI / 2);

                new ObjectRotator(entranceInfoSculpture, .5)
            }
        );

        //Load powder sculpture
        loader.load(
            "assets/powder.glb",
            function (gltf) {
                const entranceInfoSculpture = gltf.scene;
                // entranceInfoSculpture.castShadow = true;
                // entranceInfoSculpture.receiveShadow = true;
                scene.add(entranceInfoSculpture);
                entranceInfoSculpture.position.set(11.4,0.25,-3.05)
                entranceInfoSculpture.scale.setScalar(.95);
                entranceInfoSculpture.rotateZ(-Math.PI / 2);

                new ObjectRotator(entranceInfoSculpture, 2.0)
            }
        );
        loader.load(
            "assets/powderR.glb",
            function (gltf) {
                const entranceInfoSculpture = gltf.scene;
                // entranceInfoSculpture.castShadow = true;
                // entranceInfoSculpture.receiveShadow = true;
                scene.add(entranceInfoSculpture);
                entranceInfoSculpture.position.set(11.4,0.25,-3.05)
                entranceInfoSculpture.scale.setScalar(.95);
                entranceInfoSculpture.rotateZ(-Math.PI / 2);

                new ObjectRotator(entranceInfoSculpture, -2.3)
            }
        );

//////////////////////////////////////////LOAD 3D MODELS END////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////WHITEBOARD START//////////////////////////////////////////////
        //we can make this cleaner by passing more variables to the component
        const whiteboard = new Whiteboard(
            scene,
            ["assets/textures/whiteboard1.jpg",],
            camera,
            renderer, 3.2, 1.8, () => (controls.enabled = false), () => (controls.enabled = true),
            () => {
                setWhiteboard(whiteboard);
                setTextFieldOpen(true);
                controls.enabled = false
            },
            () => {
                controls.enabled = true
            });
        whiteboard.setPosition(1, 10.9, -10.4);

        const whiteboardControls = new WhiteboardControls(whiteboard, scene);
//////////////////////////////////////////////
        const whiteboardEntrance = new Whiteboard(
            scene,
            [
                "assets/textures/whiteboard1.jpg"
            ],
            camera,
            renderer,
            2.13,
            1.2,
            () => controls.enabled = false,
            () => controls.enabled = true,
            () => {
                setWhiteboard(whiteboardEntrance);
                setTextFieldOpen(true);
                controls.enabled = false
            },
            () => {
                controls.enabled = true
            }
        );
        whiteboardEntrance.setPosition(-3.12, 10.55, 12.9);
        // Example rotation
        whiteboardEntrance.rotateY(THREE.MathUtils.degToRad(-90))

        const whiteboardControlsEntrance = new WhiteboardControls(whiteboardEntrance, scene);
        // const postItNoteEntrance01a = new PostItNote(scene,-10.6+6,.85,7,0.0, "assets/icons/whiteboard-icons-05.jpg", "plane2");
        // const postItNoteEntrance02a = new PostItNote(scene,-10.9+6,.85,7,0.0, "assets/icons/whiteboard-icons-04.jpg", "plane2");
        // const postItNoteEntrance03a = new PostItNote(scene,-11.2+6,.85,7,0.0, "assets/icons/whiteboard-icons-03.jpg", "plane2");
        // const postItNoteEntrance04a = new PostItNote(scene,-11.5+6,.85,7,0.0, "assets/icons/whiteboard-icons-02.jpg", "plane2");
        // const postItNoteEntrance05a = new PostItNote(scene,-11.8+6,.85,7,0.0,  "assets/icons/whiteboard-icons-01.jpg", "plane2");
        //
        // const postItNoteEntrance06a = new PostItNote(scene,-10.6+6,.58,7,0.0,"assets/icons/whiteboard-icons-10.jpg", "plane2");
        // const postItNoteEntrance07a = new PostItNote(scene,-10.9+6,.58,7,0.0,"assets/icons/whiteboard-icons-09.jpg", "plane2");
        // const postItNoteEntrance08a = new PostItNote(scene,-11.2+6,.58,7,0.0,"assets/icons/whiteboard-icons-08.jpg", "plane2");
        // const postItNoteEntrance09a = new PostItNote(scene,-11.5+6,.58,7,0.0,"assets/icons/whiteboard-icons-07.jpg", "plane2");
        // const postItNoteEntrance10a = new PostItNote(scene,-11.8+6,.58,7,0.0,"assets/icons/whiteboard-icons-06.jpg", "plane2");

        // const postItNoteEntrance01 = new PostItNote(scene, -3.15, 0.8, 15.5, 0.0, "assets/icons/whiteboard-icons-01.jpg", "plane_entrance", -90);
        // const postItNoteEntrance02 = new PostItNote(scene, -3.15, 0.8, 15.5 - .25, 0.0, "assets/icons/whiteboard-icons-02.jpg", "plane_entrance", -90);
        // const postItNoteEntrance03 = new PostItNote(scene, -3.15, 0.8, 15.5 - .50, 0.0, "assets/icons/whiteboard-icons-03.jpg", "plane_entrance", -90);
        // const postItNoteEntrance04 = new PostItNote(scene, -3.15, 0.8, 15.5 - .75, 0.0, "assets/icons/whiteboard-icons-04.jpg", "plane_entrance", -90);
        // const postItNoteEntrance05 = new PostItNote(scene, -3.15, 0.8, 15.5 - 1.0, 0.0, "assets/icons/whiteboard-icons-05.jpg", "plane_entrance", -90);
        // const postItNoteEntrance06 = new PostItNote(scene, -3.15, 0.55, 15.5, 0.0, "assets/icons/whiteboard-icons-06.jpg", "plane_entrance", -90);
        // const postItNoteEntrance07 = new PostItNote(scene, -3.15, 0.55, 15.5 - .25, 0.0, "assets/icons/whiteboard-icons-07.jpg", "plane_entrance", -90);
        // const postItNoteEntrance08 = new PostItNote(scene, -3.15, 0.55, 15.5 - .50, 0.0, "assets/icons/whiteboard-icons-08.jpg", "plane_entrance", -90);
        // const postItNoteEntrance09 = new PostItNote(scene, -3.15, 0.55, 15.5 - .75, 0.0, "assets/icons/whiteboard-icons-09.jpg", "plane_entrance", -90);
        // const postItNoteEntrance10 = new PostItNote(scene, -3.15, 0.55, 15.5 - 1.0, 0.0, "assets/icons/whiteboard-icons-10.jpg", "plane_entrance", -90);
/////////////////////////////////////////////
        const whiteboardEntrance02 = new Whiteboard(
            scene, ["assets/textures/whiteboard0.jpg",], camera,
            renderer, 2.13, 1.2, () => (controls.enabled = false), () => (controls.enabled = true),
            () => {
                setWhiteboard(whiteboardEntrance02);
                setTextFieldOpen(true);
                controls.enabled = false
            },
            () => {
                controls.enabled = true
            });
        whiteboardEntrance02.setPosition(-3.12, 1.56, 12.9 + 1.5);
        whiteboardEntrance02.rotateY(THREE.MathUtils.degToRad(-90))
        const whiteboardControlsEntrance02 = new WhiteboardControls(whiteboardEntrance02, scene);
/////////////////////////////////////////////
        const whiteboardGallery00 = new Whiteboard(
            scene, ["assets/textures/whiteboard0.jpg",], camera,
            renderer, 2.13, 1.2, () => (controls.enabled = false), () => (controls.enabled = true),
            () => {
                setWhiteboard(whiteboardGallery02);
                setTextFieldOpen(true);
                controls.enabled = false
            },
            () => {
                controls.enabled = true
            });
        whiteboardGallery00.setPosition(0.08 +10, 1.56, 12.54);
        whiteboardGallery00.setRotation(0, 180, 0);
        const whiteboardControlsGallery00 = new WhiteboardControls(whiteboardGallery00, scene);
/////////////////////////////////////////////
        const whiteboardGallery = new Whiteboard(
            scene, ["assets/textures/whiteboard1.jpg",], camera,
            renderer, 2.13, 1.2, () => (controls.enabled = false), () => (controls.enabled = true),
            () => {
                setWhiteboard(whiteboardGallery);
                setTextFieldOpen(true);
                controls.enabled = false
            },
            () => {
                controls.enabled = true
            });
        whiteboardGallery.setPosition(0.08 + 7, 1.56, 12.54);
        whiteboardGallery.setRotation(0, 180, 0);
        const whiteboardControlsGallery = new WhiteboardControls(whiteboardGallery, scene);

        const postItNoteGallery01 = new PostItNote(scene, 7.00        , 0.80, 12.50 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-01.jpg", "plane_gallery", -180);
        const postItNoteGallery02 = new PostItNote(scene, 7.00 - 0.25 , 0.80, 12.50 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-02.jpg", "plane_gallery", -180);
        const postItNoteGallery03 = new PostItNote(scene, 7.00 - 0.50 , 0.80, 12.50 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-03.jpg", "plane_gallery", -180);
        const postItNoteGallery04 = new PostItNote(scene, 7.00 - 0.75 , 0.80, 12.50 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-04.jpg", "plane_gallery", -180);
        const postItNoteGallery05 = new PostItNote(scene, 7.00 - 1.00 , 0.80, 12.50 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-05.jpg", "plane_gallery", -180);

        const postItNoteGallery06 = new PostItNote(scene, 7.00        , 0.55, 12.50 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-06.jpg", "plane_gallery", -180);
        const postItNoteGallery07 = new PostItNote(scene, 7.00 - 0.25 , 0.55, 12.50 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-07.jpg", "plane_gallery", -180);
        const postItNoteGallery08 = new PostItNote(scene, 7.00 - 0.50 , 0.55, 12.50 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-08.jpg", "plane_gallery", -180);
        const postItNoteGallery09 = new PostItNote(scene, 7.00 - 0.75 , 0.55, 12.50 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-09.jpg", "plane_gallery", -180);
        // const postItNoteGallery10 = new PostItNote(scene, 7.00 - 1.00 , 0.55, 12.50 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-xx.jpg", "plane_gallery", -180);
        // addPlane("plane_gallery", 7.0, 1.2, 12.3, 2, 2, 180);
/////////////////////////////////////////////
        const whiteboardGallery02 = new Whiteboard(
            scene, ["assets/textures/whiteboard2.jpg",], camera,
            renderer, 2.13, 1.2, () => (controls.enabled = false), () => (controls.enabled = true),
            () => {
                setWhiteboard(whiteboardGallery02);
                setTextFieldOpen(true);
                controls.enabled = false
            },
            () => {
                controls.enabled = true
            });
        whiteboardGallery02.setPosition(0.08 + 4, 1.56, 12.54);
        whiteboardGallery02.setRotation(0, 180, 0);
        const whiteboardControlsGallery02 = new WhiteboardControls(whiteboardGallery02, scene);
/////////////////////////////////////////////
        const whiteboardSpeaker = new Whiteboard(
            scene, ["assets/textures/whiteboard1.jpg",], camera,
            renderer, 2.13, 1.2, () => (controls.enabled = false), () => (controls.enabled = true),
            () => {
                setWhiteboard(whiteboardSpeaker);
                setTextFieldOpen(true);
                controls.enabled = false
            },
            () => {
                controls.enabled = true
            });
        whiteboardSpeaker.setPosition(-14.95, 1.57, 2.07+.8);
        whiteboardSpeaker.setRotation(0, 90, 0);
        const whiteboardControlSpeaker = new WhiteboardControls(whiteboardSpeaker, scene);

        // const postItNoteSpeaker01 = new PostItNote(scene, -12.90         , 0.80, 4.49 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-01.jpg", "plane_speaker", 180);
        // const postItNoteSpeaker02 = new PostItNote(scene, -12.90 - 0.25  , 0.80, 4.49 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-02.jpg", "plane_speaker", 180);
        // const postItNoteSpeaker03 = new PostItNote(scene, -12.90 - 0.50  , 0.80, 4.49 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-03.jpg", "plane_speaker", 180);
        // const postItNoteSpeaker04 = new PostItNote(scene, -12.90 - 0.75  , 0.80, 4.49 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-04.jpg", "plane_speaker", 180);
        // const postItNoteSpeaker05 = new PostItNote(scene, -12.90 - 1.00  , 0.80, 4.49 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-05.jpg", "plane_speaker", 180);
        //
        // const postItNoteSpeaker06 = new PostItNote(scene, -12.90         , 0.55, 4.49 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-06.jpg", "plane_speaker", 180);
        // const postItNoteSpeaker07 = new PostItNote(scene, -12.90 - 0.25  , 0.55, 4.49 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-07.jpg", "plane_speaker", 180);
        // const postItNoteSpeaker08 = new PostItNote(scene, -12.90 - 0.50  , 0.55, 4.49 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-08.jpg", "plane_speaker", 180);
        // const postItNoteSpeaker09 = new PostItNote(scene, -12.90 - 0.75  , 0.55, 4.49 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-09.jpg", "plane_speaker", 180);

        const postItNoteSpeaker01 = new PostItNote(scene, -14.93  , 0.80, 3.00        , 0.0, "assets/icons/LAS_PN_01/GROUP1-p-01.jpg", "plane_speaker", 90);
        const postItNoteSpeaker02 = new PostItNote(scene, -14.93  , 0.80, 3.00 - 0.25 , 0.0, "assets/icons/LAS_PN_01/GROUP1-p-02.jpg", "plane_speaker", 90);
        const postItNoteSpeaker03 = new PostItNote(scene, -14.93  , 0.80, 3.00 - 0.50 , 0.0, "assets/icons/LAS_PN_01/GROUP1-p-03.jpg", "plane_speaker", 90);
        const postItNoteSpeaker04 = new PostItNote(scene, -14.93  , 0.80, 3.00 - 0.75 , 0.0, "assets/icons/LAS_PN_01/GROUP1-p-04.jpg", "plane_speaker", 90);
        const postItNoteSpeaker05 = new PostItNote(scene, -14.93  , 0.80, 3.00 - 1.00 , 0.0, "assets/icons/LAS_PN_01/GROUP1-p-05.jpg", "plane_speaker", 90);

        const postItNoteSpeaker06 = new PostItNote(scene, -14.93  , 0.55, 3.00        , 0.0, "assets/icons/LAS_PN_01/GROUP1-p-06.jpg", "plane_speaker", 90);
        const postItNoteSpeaker07 = new PostItNote(scene, -14.93  , 0.55, 3.00 - 0.25 , 0.0, "assets/icons/LAS_PN_01/GROUP1-p-07.jpg", "plane_speaker", 90);
        const postItNoteSpeaker08 = new PostItNote(scene, -14.93  , 0.55, 3.00 - 0.50 , 0.0, "assets/icons/LAS_PN_01/GROUP1-p-08.jpg", "plane_speaker", 90);
        const postItNoteSpeaker09 = new PostItNote(scene, -14.93  , 0.55, 3.00 - 0.75 , 0.0, "assets/icons/LAS_PN_01/GROUP1-p-09.jpg", "plane_speaker", 90);
        const postItNoteSpeaker10 = new PostItNote(scene, -14.93  , 0.55, 3.00 - 1.00 , 0.0, "assets/icons/LAS_PN_01/GROUP1-p-10.jpg", "plane_speaker", 90);


        // addPlane("plane_speaker", -12.90, 1.2, 4.5, 2, 2, 180);
/////////////////////////////////////////////
/////////////////////////////////////////////
        const whiteboardSpeaker02 = new Whiteboard(
            scene, ["assets/textures/whiteboard2.jpg",], camera,
            renderer, 2.13, 1.2, () => (controls.enabled = false), () => (controls.enabled = true),
            () => {
                setWhiteboard(whiteboardSpeaker02);
                setTextFieldOpen(true);
                controls.enabled = false
            },
            () => {
                controls.enabled = true
            });
        whiteboardSpeaker02.setPosition(-14.95+2.05, 1.57, 2.07 + 2.42-.01);
        whiteboardSpeaker02.setRotation(0, 180, 0);
/////////////////////////////////////////////
        const whiteboardControlSpeaker02 = new WhiteboardControls(whiteboardSpeaker02, scene);
        const whiteboardSpeaker00 = new Whiteboard(
            scene, ["assets/textures/whiteboard0.jpg",], camera,
            renderer, 2.13, 1.2, () => (controls.enabled = false), () => (controls.enabled = true),
            () => {
                setWhiteboard(whiteboardSpeaker00);
                setTextFieldOpen(true);
                controls.enabled = false
            },
            () => {
                controls.enabled = true
            });
        whiteboardSpeaker00.setPosition(-14.95, 1.57, 2.07 - 3.0+.8);
        whiteboardSpeaker00.setRotation(0, 90, 0);
        const whiteboardControlSpeaker00 = new WhiteboardControls(whiteboardSpeaker00, scene);
/////////////////////////////////////////////
        const whiteboardLungRoom00 = new Whiteboard(
            scene, ["assets/textures/whiteboard2.jpg",], camera,
            renderer, 2.13, 1.2, () => (controls.enabled = false), () => (controls.enabled = true),
            () => {
                setWhiteboard(whiteboardLungRoom00);
                setTextFieldOpen(true);
                controls.enabled = false
            },
            () => {
                controls.enabled = true
            });
        whiteboardLungRoom00.setPosition(-30.1, 1.55, -8.8);
        whiteboardLungRoom00.setRotation(0, 45, 0);
        const whiteboardControlLungRoom00 = new WhiteboardControls(whiteboardLungRoom00, scene);
/////////////////////////////////////////////
        const whiteboardLungRoom = new Whiteboard(
            scene, ["assets/textures/whiteboard0.jpg",], camera,
            renderer, 2.13, 1.2, () => (controls.enabled = false), () => (controls.enabled = true),
            () => {
                setWhiteboard(whiteboardLungRoom);
                setTextFieldOpen(true);
                controls.enabled = false
            },
            () => {
                controls.enabled = true
            });
        whiteboardLungRoom.setPosition(-26.1+3, 1.55, -10.48);
        whiteboardLungRoom.setRotation(0, 0, 0);
        const whiteboardControlLungRoom = new WhiteboardControls(whiteboardLungRoom, scene);


        const postItNoteLungroom01 = new PostItNote(scene, -26.00        , 0.80, -10.45 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-01.jpg", "plane_lungroom", 0);
        const postItNoteLungroom02 = new PostItNote(scene, -26.00 - 0.25 , 0.80, -10.45 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-02.jpg", "plane_lungroom", 0);
        const postItNoteLungroom03 = new PostItNote(scene, -26.00 - 0.50 , 0.80, -10.45 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-03.jpg", "plane_lungroom", 0);
        const postItNoteLungroom04 = new PostItNote(scene, -26.00 - 0.75 , 0.80, -10.45 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-04.jpg", "plane_lungroom", 0);
        const postItNoteLungroom05 = new PostItNote(scene, -26.00 - 1.00 , 0.80, -10.45 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-05.jpg", "plane_lungroom", 0);

        const postItNoteLungroom06 = new PostItNote(scene, -26.00        , 0.55, -10.45 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-06.jpg", "plane_lungroom", 0);
        const postItNoteLungroom07 = new PostItNote(scene, -26.00 - 0.25 , 0.55, -10.45 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-07.jpg", "plane_lungroom", 0);
        const postItNoteLungroom08 = new PostItNote(scene, -26.00 - 0.50 , 0.55, -10.45 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-08.jpg", "plane_lungroom", 0);
        const postItNoteLungroom09 = new PostItNote(scene, -26.00 - 0.75 , 0.55, -10.45 , 0.0, "assets/icons/SPK_PN_01/GROUP2-p-09.jpg", "plane_lungroom", 0);
        // const postItNoteLungroom10 = new PostItNote(scene, -26.00 - 1.00 , 0.55, -10.45 , 0.0, "assets/icons/SPK_PN_01/group1-p1.jpg", "plane_lungroom", 0);

        // addPlane("plane_lungroom", -26.1, 1.2, -10.2, 2, 2, 0);
/////////////////////////////////////////////
        const whiteboardLungRoom02 = new Whiteboard(
            scene, ["assets/textures/whiteboard1.jpg",], camera,
            renderer, 2.13, 1.2, () => (controls.enabled = false), () => (controls.enabled = true),
            () => {
                setWhiteboard(whiteboardLungRoom02);
                setTextFieldOpen(true);
                controls.enabled = false
            },
            () => {
                controls.enabled = true
            });
        whiteboardLungRoom02.setPosition(-26.1, 1.55, -10.48);
        whiteboardLungRoom02.setRotation(0, 0, 0);
        const whiteboardControlLungRoom02 = new WhiteboardControls(whiteboardLungRoom02, scene);
/////////////////////////////////////////////
        const whiteboardLastRoom00 = new Whiteboard(
            scene, ["assets/textures/whiteboard2.jpg",], camera,
            renderer, 2.13, 1.2, () => (controls.enabled = false), () => (controls.enabled = true),
            () => {
                setWhiteboard(whiteboardLastRoom00);
                setTextFieldOpen(true);
                controls.enabled = false
            },
            () => {
                controls.enabled = true
            });
        whiteboardLastRoom00.setPosition(-41.54, 3.6, 18.83);
        whiteboardLastRoom00.setRotation(0, 118.5, 0);
        const whiteboardControlLastRoom00 = new WhiteboardControls(whiteboardLastRoom00, scene);
/////////////////////////////////////////////
        const whiteboardLastRoom = new Whiteboard(
            scene, ["assets/textures/whiteboard1.jpg",], camera,
            renderer, 2.13, 1.2, () => (controls.enabled = false), () => (controls.enabled = true),
            () => {
                setWhiteboard(whiteboardLastRoom);
                setTextFieldOpen(true);
                controls.enabled = false
            },
            () => {
                controls.enabled = true
            });
        whiteboardLastRoom.setPosition(-43.0, 3.6, 16.20);
        whiteboardLastRoom.setRotation(0, 118.5, 0);
        const whiteboardControlLastRoom = new WhiteboardControls(whiteboardLastRoom, scene);

        const postItNoteLastRoom01 = new PostItNote(scene, -43.00     , 2.0 + 0.80, 16.10        , 0.0, "assets/icons/GAL_PN_01/GROUP3-p-01.jpg", "plane_lastroom", 118);
        const postItNoteLastRoom02 = new PostItNote(scene, -43.00-.12 , 2.0 + 0.80, 16.10 - 0.25 , 0.0, "assets/icons/GAL_PN_01/GROUP3-p-02.jpg", "plane_lastroom", 118);;
        const postItNoteLastRoom03 = new PostItNote(scene, -43.00-.24 , 2.0 + 0.80, 16.10 - 0.50 , 0.0, "assets/icons/GAL_PN_01/GROUP3-p-03.jpg", "plane_lastroom", 118);
        const postItNoteLastRoom04 = new PostItNote(scene, -43.00-.36 , 2.0 + 0.80, 16.10 - 0.75 , 0.0, "assets/icons/GAL_PN_01/GROUP3-p-04.jpg", "plane_lastroom", 118);
        const postItNoteLastRoom05 = new PostItNote(scene, -43.00-.48 , 2.0 + 0.80, 16.10 - 1.00 , 0.0, "assets/icons/GAL_PN_01/GROUP3-p-05.jpg", "plane_lastroom", 118);

        const postItNoteLastRoom06 = new PostItNote(scene, -43.00     , 2.0 + 0.55, 16.10        , 0.0, "assets/icons/GAL_PN_01/GROUP3-p-06.jpg", "plane_lastroom", 118);
        const postItNoteLastRoom07 = new PostItNote(scene, -43.00-.12 , 2.0 + 0.55, 16.10 - 0.25 , 0.0, "assets/icons/GAL_PN_01/GROUP3-p-07.jpg", "plane_lastroom", 118);
        const postItNoteLastRoom08 = new PostItNote(scene, -43.00-.24 , 2.0 + 0.55, 16.10 - 0.50 , 0.0, "assets/icons/GAL_PN_01/GROUP3-p-08.jpg", "plane_lastroom", 118);
        //const postItNoteLastRoom09 = new PostItNote(scene, -43.00-.36 , 2.0 + 0.55, 16.10 - 0.75 , 0.0, "assets/icons/GAL_PN_01/GROUP3-p-09.jpg", "plane_lastroom", 118);
        // const postItNoteLastRoom10 = new PostItNote(scene, -43.00-.48 , 2.0 + 0.55, 16.10 - 1.00 , 0.0, "assets/icons/GAL_PN_01/GROUP3-p-xx.jpg",  "plane_lastroom", 118);

        // addPlane("plane_lastroom", -40.4, 1.2, 18.0, 2, 2, 0);
/////////////////////////////////////////////
        const whiteboardLastRoom02 = new Whiteboard(
            scene, ["assets/textures/whiteboard0.jpg",], camera,
            renderer, 2.13, 1.2, () => (controls.enabled = false), () => (controls.enabled = true),
            () => {
                setWhiteboard(whiteboardLastRoom02);
                setTextFieldOpen(true);
                controls.enabled = false
            },
            () => {
                controls.enabled = true
            });
        whiteboardLastRoom02.setPosition(-44.42, 3.6, 13.545);
        whiteboardLastRoom02.setRotation(0, 118.5, 0);
        const whiteboardControlLastRoom02 = new WhiteboardControls(whiteboardLastRoom02, scene);
/////////////////////////////////////////////

        // loader.load(
        //     "assets/models/whiteboard.glb",
        //     function (gltf) {
        //       const whiteboardGLBEntrance = gltf.scene;
        //       whiteboardGLBEntrance.castShadow = true;
        //       whiteboardGLBEntrance.receiveShadow = true;
        //       scene.add(whiteboardGLBEntrance);
        //       whiteboardGLBEntrance.scale.set(1.01, 1.01, 1.01);
        //       whiteboardGLBEntrance.position.set(-12+6, 1.9, 7.0);
        //       whiteboardGLBEntrance.rotation.set(0, 0, 0);
        //     }
        // );
////////////////////////////////////////////WHITEBOARD END//////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////PADS START////////////////////////////////////////////////
        const foyerCenter = new MovePad(true,scene, controls);
        foyerCenter.position = new THREE.Vector3(-14.5, 2.1, 22.0);
        foyerCenter.rotateTo =           new THREE.Quaternion(-0.05734316195236519,-0.4462848450256851,-0.028670880517826493,0.892591496423777);
        foyerCenter.rotateToViewEast =   new THREE.Quaternion(-0.05734316195236519,-0.4462848450256851,-0.028670880517826493,0.892591496423777);
        foyerCenter.rotateToViewSouth =  new THREE.Quaternion(-0.0008082686069240521,0.7204253211119422,0.0008396116632158298,0.6935315410670729);
        foyerCenter.rotateToViewWest =   new THREE.Quaternion(-0.0008082686069240521,0.7204253211119422,0.0008396116632158298,0.6935315410670729);

        const foyerStairTop = new MovePad(false,scene, controls);
        foyerStairTop.position = new THREE.Vector3(-7.0, 2.1, 21.5);
        foyerStairTop.rotateTo =          new THREE.Quaternion(-0.18911628773028327,0.21227506799701104,0.041912578007645744,0.9578192214748741);
        foyerStairTop.rotateToViewEast =  new THREE.Quaternion(-0.18911628773028327,0.21227506799701104,0.041912578007645744,0.9578192214748741);
        foyerStairTop.rotateToViewSouth = new THREE.Quaternion(-0.06426072700917876,0.7564316269879244,0.07518165168398104,0.6465519870101104);
        foyerStairTop.rotateToViewWest =  new THREE.Quaternion(-0.06426072700917876,0.7564316269879244,0.07518165168398104,0.6465519870101104);
////////////////////////////////////////////////////////////////////////////////
        const entranceStairs = new MovePad(false,scene, controls, -90-45, .3);
        entranceStairs.position = new THREE.Vector3(-7.0, 1.25, 19.0);
        entranceStairs.rotateTo =  new THREE.Quaternion(-0.20509443532769017,0.14507941962072413,0.030756375428488934,0.9674410989670801);
        entranceStairs.rotateToViewEast =  new THREE.Quaternion(-0.20509443532769017,0.14507941962072413,0.030756375428488934,0.9674410989670801);
        entranceStairs.rotateToViewSouth =  new THREE.Quaternion(-0.0032889544210329934,0.9955326818235124,0.08641972939298471,0.03788789479167321);
        entranceStairs.rotateToViewWest =  new THREE.Quaternion(-0.0032889544210329934,0.9955326818235124,0.08641972939298471,0.03788789479167321);

        const entranceStairsBot = new MovePad(false,scene, controls, -90, );
        entranceStairsBot.position = new THREE.Vector3(-6.966215486200743,0.02,14.646157422682366);
        entranceStairsBot.rotateTo =  new THREE.Quaternion(-0.20509443532769017,0.14507941962072413,0.030756375428488934,0.9674410989670801);
        entranceStairsBot.rotateToViewEast =  new THREE.Quaternion(-0.0024598781905383234,0.997488661558316,0.04472145394149512,0.054866297663007796);
        entranceStairsBot.rotateToViewSouth =  new THREE.Quaternion(-0.08181686667858226,0.7897526804840277,0.108002443571244,0.5982743318727896);
        entranceStairsBot.rotateToViewWest =  new THREE.Quaternion(-0.08181686667858226,0.7897526804840277,0.108002443571244,0.5982743318727896);

        const entranceCenter = new MovePad(true,scene, controls, -90, 1.0);
        entranceCenter.position = new THREE.Vector3(-15.10, 0.02, 18.0);
        entranceCenter.rotateTo = new THREE.Quaternion(-0.04189463411807688,-0.15180544029204687,-0.006440330267005737,0.9875011240884247);
        entranceCenter.rotateToViewSouth =  new THREE.Quaternion(-0.004619566852089378,0.9938649100851634,0.045615805919005395,0.10064988004895295);
        entranceCenter.rotateToViewEast =  new THREE.Quaternion(-0.06986803118310844,-0.7816683294423042,-0.08904317436721791,0.6133387270212788);
        entranceCenter.rotateToViewWest =  new THREE.Quaternion(-0.04189463411807688,-0.15180544029204687,-0.006440330267005737,0.9875011240884247);
        MovePad.currentPad = entranceCenter; //Important to set the current pad at the start

        const entrancePowerpoint2 = new MovePad(false,scene, controls,0,.6,null, "assets/icons/book-open.png");
        entrancePowerpoint2.position = new THREE.Vector3(-22.95,1.15,10.65)
        entrancePowerpoint2.rotateTo = new THREE.Quaternion(0.0010310995583486782,0.7043394759895153,-0.0010230781680764427,0.7098618124026519);
        entrancePowerpoint2.rotateToViewSouth = new THREE.Quaternion(0.0010310995583486782,0.7043394759895153,-0.0010230781680764427,0.7098618124026519);
        entrancePowerpoint2.rotateToViewEast = new THREE.Quaternion(0.0010310995583486782,0.7043394759895153,-0.0010230781680764427,0.7098618124026519);
        entrancePowerpoint2.rotateToViewWest = new THREE.Quaternion(0.0010310995583486782,0.7043394759895153,-0.0010230781680764427,0.7098618124026519);
        entrancePowerpoint2.offsetPosition = new THREE.Vector3(-22.95,1.15,10.65)

        // setPosition(-22.95,2.60,10.65)
        // new THREE.Quaternion(0.0010310995583486782,0.7043394759895153,-0.0010230781680764427,0.7098618124026519);

        const entrancePowerpoint = new MovePad(false,scene, controls,0,.6,null, "assets/icons/book-open.png");
        entrancePowerpoint.position = new THREE.Vector3(-5.729389115006568,0.02,8.448129548096295-1)
        entrancePowerpoint.rotateTo =  new THREE.Quaternion(0.031919547171280455,-0.004020290244910864,0.00012839230669227014,0.9994823476631004);
        entrancePowerpoint.rotateToViewSouth =  new THREE.Quaternion(0.031919547171280455,-0.004020290244910864,0.00012839230669227014,0.9994823476631004);
        entrancePowerpoint.rotateToViewEast =  new THREE.Quaternion(0.031919547171280455,-0.004020290244910864,0.00012839230669227014,0.9994823476631004);
        entrancePowerpoint.rotateToViewWest =  new THREE.Quaternion(0.031919547171280455,-0.004020290244910864,0.00012839230669227014,0.9994823476631004);
        entrancePowerpoint.offsetPosition = new THREE.Vector3(-5.729389115006568,0.02,8.448129548096295)

        // const entranceWhiteboard = new MovePad(false,scene, controls,0,.6,null, "assets/icons/whiteboard.png");
        // entranceWhiteboard.position = new THREE.Vector3(-3.4, .02, 14.411271439091546)
        // entranceWhiteboard.rotateTo = new THREE.Quaternion(-0.012409093251852108, -0.7175091690227584, -0.012786684020951894, 0.6963211238248384);
        // entranceWhiteboard.rotateToViewSouth = new THREE.Quaternion(-0.012409093251852108, -0.7175091690227584, -0.012786684020951894, 0.6963211238248384);
        // entranceWhiteboard.rotateToViewEast = new THREE.Quaternion(-0.012409093251852108, -0.7175091690227584, -0.012786684020951894, 0.6963211238248384);
        // entranceWhiteboard.rotateToViewWest = new THREE.Quaternion(-0.012409093251852108, -0.7175091690227584, -0.012786684020951894, 0.6963211238248384);
        // entranceWhiteboard.offsetPosition = new THREE.Vector3(-5.4, .02, 14.4);

        const entranceDoorWest = new MovePad(false,scene, controls);
        entranceDoorWest.position = new THREE.Vector3(-28.0, 2.1, 24.44);
        entranceDoorWest.rotateToViewSouth = new THREE.Quaternion(0.01010911207129374,-0.48161028619870977,0.005555858909981999,0.8763095745868005);
        entranceDoorWest.rotateToViewEast = new THREE.Quaternion(0.01010911207129374,-0.48161028619870977,0.005555858909981999,0.8763095745868005);
        entranceDoorWest.rotateTo = new THREE.Quaternion(-0.0197624090040827,0.6898219879562005,0.018843434335674304,0.7234638879066102);
        entranceDoorWest.rotateToViewWest = new THREE.Quaternion(-0.0197624090040827,0.6898219879562005,0.018843434335674304,0.7234638879066102);

        const entranceDoorEast = new MovePad(false,scene, controls);
        entranceDoorEast.position = new THREE.Vector3(-3, 0.02, 9.3);
        entranceDoorEast.rotateTo = new THREE.Quaternion(-0.02546647669706946,-0.6253450241886686,-0.020425862579054387,0.7796652123988942);
        entranceDoorEast.rotateToViewSouth =   new THREE.Quaternion(-0.03298548005524528,0.8451852719109934,0.052516043474776224,0.5308633340399587);
        entranceDoorEast.rotateToViewWest =   new THREE.Quaternion(-0.03298548005524528,0.8451852719109934,0.052516043474776224,0.5308633340399587);
        entranceDoorEast.rotateToViewEast =  new THREE.Quaternion(-0.02546647669706946,-0.6253450241886686,-0.020425862579054387,0.7796652123988942);
////////////////////////////////////////////////////////////////////////////////
        const galleryCenter = new MovePad(true,scene, controls);
        galleryCenter.position = new THREE.Vector3(5.0, 0.06, 9.5);
        galleryCenter.rotateTo = new THREE.Quaternion(0.024894280545089572,0.10593111743377907,-0.0026528414348221505,0.9940582656905453);
        galleryCenter.rotateToViewWest = new THREE.Quaternion(0.003100659429283536,0.7006344063544754,-0.0030447185905618183,0.7135072138613033);
        galleryCenter.rotateToViewSouth = new THREE.Quaternion(-0.004757816595654003,0.9940550528151119,0.0486011783826003,0.09731310607420735);
        galleryCenter.rotateToViewEast = new THREE.Quaternion(-0.052619406848136485,-0.6373849307303131,-0.04369856798371995,0.7675038001580425);


        const galleryPowerpoint = new MovePad(false,scene, controls,0,.6,null, "assets/icons/book-open.png");
        galleryPowerpoint.position = new THREE.Vector3(0.09475272076454441,.02,11.283088037831853+0.9)
        galleryPowerpoint.rotateTo = new THREE.Quaternion(0.000009533441343828933,0.9999904422907026,-0.0031997310338858853,0.002979422371758001);
        galleryPowerpoint.rotateToViewSouth = new THREE.Quaternion(0.000009533441343828933,0.9999904422907026,-0.0031997310338858853,0.002979422371758001);
        galleryPowerpoint.rotateToViewEast = new THREE.Quaternion(0.000009533441343828933,0.9999904422907026,-0.0031997310338858853,0.002979422371758001);
        galleryPowerpoint.rotateToViewWest = new THREE.Quaternion(0.000009533441343828933,0.9999904422907026,-0.0031997310338858853,0.002979422371758001);
        galleryPowerpoint.offsetPosition = new THREE.Vector3(0.09475272076454441,.02,11.283088037831853)

        const galleryChicane = new MovePad(false,scene, controls);
        galleryChicane.position = new THREE.Vector3(10, 0.06, 4.7);
        galleryChicane.rotateTo = new THREE.Quaternion(0.024894280545089572,0.10593111743377907,-0.0026528414348221505,0.9940582656905453);
        galleryChicane.rotateToViewWest = new THREE.Quaternion(0.003100659429283536,0.7006344063544754,-0.0030447185905618183,0.7135072138613033);
        galleryChicane.rotateToViewSouth = new THREE.Quaternion(-0.004757816595654003,0.9940550528151119,0.0486011783826003,0.09731310607420735);
        galleryChicane.rotateToViewEast = new THREE.Quaternion(-0.052619406848136485,-0.6373849307303131,-0.04369856798371995,0.7675038001580425);

        const galleryDoorNorth = new MovePad(false,scene, controls);
        galleryDoorNorth.position = new THREE.Vector3(0.5, 0.01, 4.6);
        galleryDoorNorth.rotateTo = new THREE.Quaternion(0.024894280545089572,0.10593111743377907,-0.0026528414348221505,0.9940582656905453);
        galleryDoorNorth.rotateToViewWest = new THREE.Quaternion(0.003100659429283536,0.7006344063544754,-0.0030447185905618183,0.7135072138613033);
        galleryDoorNorth.rotateToViewSouth = new THREE.Quaternion(-0.004757816595654003,0.9940550528151119,0.0486011783826003,0.09731310607420735);
        galleryDoorNorth.rotateToViewEast = new THREE.Quaternion(-0.052619406848136485,-0.6373849307303131,-0.04369856798371995,0.7675038001580425);
////////////////////////////////////////////////////////////////////////////////
        const ideaCenter = new MovePad(true,scene, controls);
        ideaCenter.position = new THREE.Vector3(13.6, 0.06, -7.5);
        ideaCenter.rotateTo =         new THREE.Quaternion(-0.10814488253979637,0.6035682096489403,0.08309070298377813,0.7855609688364665);
        ideaCenter.rotateToViewWest =         new THREE.Quaternion(-0.10814488253979637,0.6035682096489403,0.08309070298377813,0.7855609688364665);
        ideaCenter.rotateToViewSouth = new THREE.Quaternion(0.026701369631319364,0.9164565843016773,0.06204659964259758,-0.39439141144642775);
        ideaCenter.rotateToViewEast = new THREE.Quaternion(0.026701369631319364,0.9164565843016773,0.06204659964259758,-0.39439141144642775);

        const ideaMovies = new MovePad(false,scene, controls);
        ideaMovies.position = new THREE.Vector3(7.299486857082318,0.01,-3.383723875231802);
        ideaMovies.rotateTo =        new THREE.Quaternion(-0.000008204977462534756,0.9999527352809752,0.009685533692104852,0.0008470973223982835);
        ideaMovies.rotateToViewWest =         new THREE.Quaternion(-0.000008204977462534756,0.9999527352809752,0.009685533692104852,0.0008470973223982835);
        ideaMovies.rotateToViewSouth = new THREE.Quaternion(-0.000008204977462534756,0.9999527352809752,0.009685533692104852,0.0008470973223982835);
        ideaMovies.rotateToViewEast = new THREE.Quaternion(-0.000008204977462534756,0.9999527352809752,0.009685533692104852,0.0008470973223982835);

        const ideaDoorEast = new MovePad(false,scene, controls);
        ideaDoorEast.position = new THREE.Vector3(4.7, 0.01, -9.0);
        ideaDoorEast.rotateTo = new THREE.Quaternion(-0.09073594712820789,-0.8257721654405755,-0.13900587261006875,0.5390219717805974);
        ideaDoorEast.rotateToViewWest =                 new THREE.Quaternion(-0.025925448490601233,0.8700627885482592,0.046024603323146895,0.4901023882752819);
        ideaDoorEast.rotateToViewSouth = new THREE.Quaternion(-0.09073594712820789,-0.8257721654405755,-0.13900587261006875,0.5390219717805974);
        ideaDoorEast.rotateToViewEast = new THREE.Quaternion(-0.09073594712820789,-0.8257721654405755,-0.13900587261006875,0.5390219717805974);

        const ideaDoorSouth = new MovePad(false,scene, controls);
        ideaDoorSouth.position = new THREE.Vector3(20.1, 0.01, -1);
        ideaDoorSouth.rotateTo = new THREE.Quaternion(-0.0838519035960872,0.2715564151259846,0.02375719811220625,0.9584683443938532);
        ideaDoorSouth.rotateToViewWest = new THREE.Quaternion(-0.08507643435324254,0.6564756030594417,0.07488846272521531,0.7457838165689402);
        ideaDoorSouth.rotateToViewSouth = new THREE.Quaternion(-0.07075666307247998,0.9042405841979673,0.16516338648237058,0.38738032511453335);
        ideaDoorSouth.rotateToViewEast = new THREE.Quaternion(-0.08507643435324254,0.6564756030594417,0.07488846272521531,0.7457838165689402);
////////////////////////////////////////////////////////////////////////////////
        const speakersCenter = new MovePad(true,scene, controls);
        speakersCenter.position = new THREE.Vector3(-0.03665007492927767, .01, -6.2827094072878875)
        speakersCenter.rotateTo = new THREE.Quaternion(-0.09913565605131107,0.47867624179932283,0.05450256191139591,0.870672526258436);
        speakersCenter.rotateToViewWest = new THREE.Quaternion(-0.08512148665712077,0.7256259492366212,0.09127917319003159,0.6766752742974124);
        speakersCenter.rotateToViewSouth = new THREE.Quaternion(-0.06200810467863956,-0.8465859194434057,-0.10117499242334381,0.518855371816138);
        speakersCenter.rotateToViewEast = new THREE.Quaternion(-0.070863310097115,-0.7368324845155237,-0.07818990921974259,0.6677893523716659);

////////////////////////////////////////////////////////////////////////////////
        const speakerPowerpoint = new MovePad(false,scene, controls,0,.6,null, "assets/icons/book-open.png");
        speakerPowerpoint.position = new THREE.Vector3(-7.867322719730959,.01,2.0341275037422135+2)
        speakerPowerpoint.rotateTo =  new THREE.Quaternion(-0.000008585387435795607,0.999966962542148,0.0010653529404793245,0.008058459755653783);
        speakerPowerpoint.rotateToViewSouth =  new THREE.Quaternion(-0.000008585387435795607,0.999966962542148,0.0010653529404793245,0.008058459755653783);
        speakerPowerpoint.rotateToViewEast =  new THREE.Quaternion(-0.000008585387435795607,0.999966962542148,0.0010653529404793245,0.008058459755653783);
        speakerPowerpoint.rotateToViewWest =  new THREE.Quaternion(-0.000008585387435795607,0.999966962542148,0.0010653529404793245,0.008058459755653783);
        speakerPowerpoint.offsetPosition = new THREE.Vector3(-7.867322719730959,.5,2.0341275037422135)

        const speakersDoor = new MovePad(false,scene, controls);
        speakersDoor.position = new THREE.Vector3(-14, .01, -6.0)
        speakersDoor.rotateTo = new THREE.Quaternion(-0.19687106590166226,-0.018364741621711988,-0.0036883291533271116,0.9802504352990452);
        speakersDoor.rotateToViewWest = new THREE.Quaternion(-0.19687106590166226,-0.018364741621711988,-0.0036883291533271116,0.9802504352990452);
        speakersDoor.rotateToViewSouth =  new THREE.Quaternion(0.07169781594909132,0.880164824412238,0.14100829993240507,-0.4475331992217755);
        speakersDoor.rotateToViewEast =  new THREE.Quaternion(0.07169781594909132,0.880164824412238,0.14100829993240507,-0.4475331992217755);

        const speakersCenterTheater = new MovePad(true,scene, controls);
        speakersCenterTheater.position = new THREE.Vector3(-7.330674424607134, .01, -2.0394363871564805)
        speakersCenterTheater.rotateTo = new THREE.Quaternion(0.024894280545089572,0.10593111743377907,-0.0026528414348221505,0.9940582656905453);
        speakersCenterTheater.rotateToViewWest = new THREE.Quaternion(0.003100659429283536,0.7006344063544754,-0.0030447185905618183,0.7135072138613033);
        speakersCenterTheater.rotateToViewSouth = new THREE.Quaternion(-0.004757816595654003,0.9940550528151119,0.0486011783826003,0.09731310607420735);
        speakersCenterTheater.rotateToViewEast = new THREE.Quaternion(-0.052619406848136485,-0.6373849307303131,-0.04369856798371995,0.7675038001580425);
////////////////////////////////////////////////////////////////////////////////
        const hallwayDoorEast = new MovePad(false,scene, controls);
        hallwayDoorEast.position = new THREE.Vector3(-15.1, 0.01, -8.6);
        hallwayDoorEast.rotateTo = new THREE.Quaternion(-0.021668974999370864,-0.8465741835099055,-0.0345660865239377,0.5307049962073402);
        hallwayDoorEast.rotateToViewEast = new THREE.Quaternion(-0.021668974999370864,-0.8465741835099055,-0.0345660865239377,0.5307049962073402);
        hallwayDoorEast.rotateToViewSouth =  new THREE.Quaternion(0.03605019626851703,0.867996305867932,0.06371116367593244,-0.4911452778676423);
        hallwayDoorEast.rotateToViewWest = new THREE.Quaternion(-0.06614896178662179,0.8387045607266247,0.10461204287553998,0.5303350781876658);

        const hallwayCenter = new MovePad(true,scene, controls);
        hallwayCenter.position = new THREE.Vector3(-18.36, 0.01, -1.0);
        hallwayCenter.rotateTo = new THREE.Quaternion(-0.044000942670269654,-0.03265966593518342,-0.0014392200051624737,0.9984964656476789);
        hallwayCenter.rotateToViewSouth =  new THREE.Quaternion(0.00006688198136960323,0.9998598785311325,0.004121739120548095,-0.016224367387725508);

        const hallwayDoorSouth = new MovePad(false,scene, controls);
        hallwayDoorSouth.position = new THREE.Vector3(-18.36, 0.02, 7.03);
        hallwayDoorSouth.rotateTo = new THREE.Quaternion(-0.044000942670269654,-0.03265966593518342,-0.0014392200051624737,0.9984964656476789);
        hallwayDoorSouth.rotateToViewWest = new THREE.Quaternion(-0.044000942670269654,-0.03265966593518342,-0.0014392200051624737,0.9984964656476789);
        hallwayDoorSouth.rotateToViewSouth =  new THREE.Quaternion(0.010613931585200327,0.9840195019683312,0.06281343677657844,-0.16627518264195965);
        hallwayDoorSouth.rotateToViewEast =  new THREE.Quaternion(0.010613931585200327,0.9840195019683312,0.06281343677657844,-0.16627518264195965);

        const hallwayDoorWest = new MovePad(false,scene, controls);
        hallwayDoorWest.position = new THREE.Vector3(-21.75, 0.01, -8.8);
        hallwayDoorWest.rotateTo = new  THREE.Quaternion(-0.027894292064470652,0.9279815388217145,0.07096935702584001,0.3647403493440827);
        hallwayDoorWest.rotateToViewWest = new  THREE.Quaternion(-0.027894292064470652,0.9279815388217145,0.07096935702584001,0.3647403493440827);
        hallwayDoorWest.rotateToViewSouth =  new  THREE.Quaternion(-0.0750667928982422,-0.8303236975498255,-0.11542450531037413,0.5400043680054947);
        hallwayDoorWest.rotateToViewEast =  new THREE.Quaternion(0.060200379934714845,0.8843800622463442,0.1190241748928502,-0.4473042203556147);
////////////////////////////////////////////////////////////////////////////////
        const lungroomCenter = new MovePad(true,scene, controls);
        lungroomCenter.position = new THREE.Vector3(-26, 0.06, -5);
        lungroomCenter.rotateTo = new THREE.Quaternion(-0.0464540101122859,-0.02853947489491607,-0.0013277490476288607,0.9985117727897236);
        lungroomCenter.rotateToViewEast = new THREE.Quaternion(-0.035202226931374245,-0.6377298747250831,-0.029196876951598002,0.768901133097406);
        lungroomCenter.rotateToViewSouth =  new THREE.Quaternion(0.00016532349824057482,0.9988913957416939,0.04694226514963036,-0.0035179431452665557);
        lungroomCenter.rotateToViewWest =          new THREE.Quaternion(-0.011927200108533344,0.7119838742628775,0.012096996585899305,0.7019902900758636);

        const lungroomPowerpoint = new MovePad(false,scene, controls,0,.6,null, "assets/icons/book-open.png");
        lungroomPowerpoint.position = new THREE.Vector3(-31.03679147295774,.02,-1.6099542253729067)
        lungroomPowerpoint.rotateTo = new THREE.Quaternion(-0.0017811389230292865,0.7040464926398154,0.0017658319757647661,0.7101494529908086);
        lungroomPowerpoint.rotateToViewSouth = new THREE.Quaternion(-0.0017811389230292865,0.7040464926398154,0.0017658319757647661,0.7101494529908086);
        lungroomPowerpoint.rotateToViewEast = new THREE.Quaternion(-0.0017811389230292865,0.7040464926398154,0.0017658319757647661,0.7101494529908086);
        lungroomPowerpoint.rotateToViewWest = new THREE.Quaternion(-0.0017811389230292865,0.7040464926398154,0.0017658319757647661,0.7101494529908086);
        lungroomPowerpoint.offsetPosition = new THREE.Vector3(-29.03679147295774,.7,-1.6099542253729067)

        // setPosition(-29.03679147295774,2.2980392336825948,-1.6099542253729067)
        // THREE.Quaternion(-0.0017811389230292865,0.7040464926398154,0.0017658319757647661,0.7101494529908086);

        const lungroomStairs = new MovePad(false,scene, controls, -90-45, .3);
        lungroomStairs.position = new THREE.Vector3(-23.6, 1.1, .76 );
        lungroomStairs.rotateTo = new THREE.Quaternion(-0.10890297463882166,0.01721865020116499,0.0018866681583238427,0.9939014542112947);
        lungroomStairs.rotateToViewEast = new THREE.Quaternion(-0.035202226931374245,-0.6377298747250831,-0.029196876951598002,0.768901133097406);
        lungroomStairs.rotateToViewSouth =  new THREE.Quaternion(-0.007071605289816479,0.9923079602178955,0.1032100849818114,0.06798957894318666);
        lungroomStairs.rotateToViewWest =          new THREE.Quaternion(-0.011927200108533344,0.7119838742628775,0.012096996585899305,0.7019902900758636);

        const lungroomStairtop = new MovePad(false,scene, controls);
        lungroomStairtop.position = new THREE.Vector3(-23.5, 2.0, 4.4);
        lungroomStairtop.rotateTo = new THREE.Quaternion(-0.1900412940671736,0.11042915560814798,0.021517395980100485,0.975308520321227);
        lungroomStairtop.rotateToViewEast = new THREE.Quaternion(-0.1900412940671736,0.11042915560814798,0.021517395980100485,0.975308520321227);
        lungroomStairtop.rotateToViewSouth =  new THREE.Quaternion(-0.04542445991069497,0.871688440903544,0.08232776522005122,0.48095531966165267);
        lungroomStairtop.rotateToViewWest =         new THREE.Quaternion(-0.04542445991069497,0.871688440903544,0.08232776522005122,0.48095531966165267);

        const lungroomBalcony = new MovePad(false,scene, controls);
        lungroomBalcony.position = new THREE.Vector3(-37.5, 2.0, 5.5);
        lungroomBalcony.rotateTo = new THREE.Quaternion(-0.09295181450764807,-0.5863993883405337,-0.06798112601921,0.8017944150708111);
        lungroomBalcony.rotateToViewEast =  new THREE.Quaternion(-0.0586373840760296,-0.5333454225341343,-0.03709636813455937,0.8430469600928711);
        lungroomBalcony.rotateToViewSouth =   new THREE.Quaternion(-0.0586373840760296,-0.5333454225341343,-0.03709636813455937,0.8430469600928711);
        lungroomBalcony.rotateToViewWest =         new THREE.Quaternion(-0.01988886971739195,0.9556004150903402,0.06635956828776109,0.28640650697425873);
////////////////////////////////////////////////////////////////////////////////
        const lastroomCenter = new MovePad(true,scene, controls);
        lastroomCenter.position = new THREE.Vector3(-38.20820899129713, 2.1, 15.808441853177843)
        lastroomCenter.rotateTo = new THREE.Quaternion(-0.0464540101122859,-0.02853947489491607,-0.0013277490476288607,0.9985117727897236);
        lastroomCenter.rotateToViewEast = new THREE.Quaternion(-0.035202226931374245,-0.6377298747250831,-0.029196876951598002,0.768901133097406);
        lastroomCenter.rotateToViewSouth =  new THREE.Quaternion(0.00016532349824057482,0.9988913957416939,0.04694226514963036,-0.0035179431452665557);
        lastroomCenter.rotateToViewWest =          new THREE.Quaternion(-0.011927200108533344,0.7119838742628775,0.012096996585899305,0.7019902900758636);

        const lastroomPowerpoint = new MovePad(false,scene, controls,0,.6,null, "assets/icons/book-open.png");
        lastroomPowerpoint.position = new THREE.Vector3(-38.1278888670727,2.1,21.62557635712144)
        lastroomPowerpoint.rotateTo =  new THREE.Quaternion(0.008810176915539833,0.8579611994118399,-0.014722203097662829,0.5134279090801992);
        lastroomPowerpoint.rotateToViewSouth =  new THREE.Quaternion(-0.0033978326150973827,0.8569030508145125,0.005648841409126222,0.5154354536011617);
        lastroomPowerpoint.rotateToViewEast =  new THREE.Quaternion(-0.0033978326150973827,0.8569030508145125,0.005648841409126222,0.5154354536011617);
        lastroomPowerpoint.rotateToViewWest =  new THREE.Quaternion(-0.0033978326150973827,0.8569030508145125,0.005648841409126222,0.5154354536011617);
        lastroomPowerpoint.offsetPosition = new THREE.Vector3(-38.1278888670727,2.1,21.62557635712144)



        const lastroomDoorWest = new MovePad(false,scene, controls);
        lastroomDoorWest.position = new THREE.Vector3(-34.6, 2.03, 24.44);
        lastroomDoorWest.rotateTo = new THREE.Quaternion(-0.10883860761403222,0.3203675401657365,0.037082590118398207,0.9402891460667324);
        lastroomDoorWest.rotateToViewEast =         new THREE.Quaternion(-0.061282755544691606,-0.793685104809723,-0.08109555408007682,0.5997765328724307);
        lastroomDoorWest.rotateToViewSouth =          new THREE.Quaternion(-0.061282755544691606,-0.793685104809723,-0.08109555408007682,0.5997765328724307);
        lastroomDoorWest.rotateToViewWest =       new THREE.Quaternion(-0.10883860761403222,0.3203675401657365,0.037082590118398207,0.9402891460667324);
/////////////////////////////////////////////////PADS END///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////MAPS START////////////////////////////////////////////////
//     const entrancMapPad = new ClickableMap(scene, controls);
//     entrancMapPad.position = new THREE.Vector3(-11.35, 0.22, 12.25);
//     entrancMapPad.rotation = new THREE.Vector3(-11.35, 0.22, 12.25);
//     entrancMapPad.rotateTo = new THREE.Quaternion(0.043, 0.677, -0.039, 0.733);


//////////////////////////////////////////////MAPS END////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////CLICK EVENTS START/////////////////////////////////////////
        const handleClickEvent = () => {
            controls.targetRotation = null; // if we are rotating to a movepad target, stop rotating
            raycaster.setFromCamera(mouse, camera);
            const intersects = raycaster.intersectObjects(scene.children, true); //array
            if (intersects.length > 0 && intersects[0]) {
                const clickable = Clickable.clickables.get(intersects[0].object.uuid);
                if (clickable) clickable.handleClicked();
            }
        };

        let dragging = false;
        let storedDraggable = null;

        const handlePointerDownEvent = (e) => {
            raycaster.setFromCamera(mouse, camera);
            const intersects = raycaster.intersectObjects(scene.children, true);
            if (intersects.length > 0 && intersects[0]) {
                const draggable = Draggable.draggables.get(intersects[0].object.uuid);
                if (draggable) {
                    e.stopPropagation();
                    whiteboard.isDrawing = false;
                    dragging = true;
                    storedDraggable = draggable;
                }
            }
        };

        // using a plane to project the drags onto. This is not ideal and could be more
        // performant but it'll do for prototype stage
        const planes = {};

        function addPlane(id, x, y, z, w, h, r) {
            const geo = new THREE.PlaneBufferGeometry(w, h);
            planes[id] = new THREE.Mesh(
                geo,
                new THREE.MeshBasicMaterial({color: "red", visible: false})
            );
            planes[id].position.set(x, y, z);
            planes[id].rotateY(r * THREE.Math.DEG2RAD);
        }

        // addPlane("plane1", 1.5, 10.9, -10.4, 5, 5, 0);
        // addPlane("plane2", -5.6, 10.85, 7, 5, 5, 0);
        // addPlane("plane_entrance", -3.15, 1.2, 14.5, 2, 2, -90);
        addPlane("plane_gallery", 7.0, 1.2, 12.55, 2, 2, 180);
        // addPlane("plane_speaker", -12.90, 1.2, 4.5, 2, 2, 180);
        addPlane("plane_speaker", -14.99, 1.2, 2.8, 2, 2, 90);
        addPlane("plane_lungroom", -26.1, 1.2, -10.5, 2, 2, 0);
        addPlane("plane_lastroom", -43.00, 2.0+1.2, 16.10, 2, 2, 118);

        // const postItNoteLastRoom10 = new PostItNote(scene, -43.00 , 1.8 + 0.55, 16.10 - 1.00 , 0.0, "assets/icons/whiteboard-icons-10.jpg", "plane_lastroom", 118);


        for (const key in planes) {
            scene.add(planes[key]); // for some reason raycaster only works if this is in the scene
        }

        function checkDraggableIntersection (raycaster) {
            const intersects = raycaster.intersectObject(planes[storedDraggable.meta.planeId], true);
            if (intersects.length > 0 && intersects[0]) {
                const pos = intersects[0].point;
                storedDraggable.handleDragged({newPos: pos, r: planes[storedDraggable.meta.planeId].rotation});
            }
        }

        function checkHoverables (raycaster) {
            document.body.style.cursor = 'default';


            const hovering = Array
                .from(Hoverable.hoverables)
                .map(([_name, value]) => value)
                .filter((x) => x.hovered)

            const intersects = raycaster.intersectObjects(scene.children, true);

            // Check if existing hovered items match anything in the intersections. If not then mark them as unhovered.
            hovering.forEach((x) => {
                if(!intersects.find((y) => y.object === x.object)){
                    x.handleUnhover();
                }
            })

            var i = 0;

            for (const intersect of intersects) {
                if (i > 1) return; // NOTE: the whiteboards always seem to be the 2nd item
                const hoverable = Hoverable.hoverables.get(intersect.object.uuid);
                if (hoverable) {
                    hoverable.handleHovered();
                }
                i++;
            }
        }

        const updateMouse = (e, x, y) => {
            mouse.x = (x / window.innerWidth) * 2 - 1;
            mouse.y = -(y / window.innerHeight) * 2 + 1;

            raycaster.setFromCamera(mouse, camera);
            checkHoverables(raycaster);

            if (!dragging) return;
            whiteboard.isDrawing = false;
            e.stopPropagation();
            raycaster.setFromCamera(mouse, camera);
            const intersects = raycaster.intersectObject(planes[storedDraggable.meta.planeId], true);
            if (intersects.length > 0 && intersects[0]) {
                const pos = intersects[0].point;
                storedDraggable.handleDragged({newPos: pos, r: planes[storedDraggable.meta.planeId].rotation});
            }

            checkDraggableIntersection(raycaster);
        };

        const handlePointerMoveEvent = (e) => {
            updateMouse(e, e.clientX, e.clientY);
        };

        const handleTouchMoveEvent = (e) => {
            const ex = e.changedTouches[0].pageX;
            const ey = e.changedTouches[0].pageY;
            updateMouse(e, ex, ey);
        };

        const handlePointerUpEvent = () => {
            dragging = false;
        };

        const handleTouchStartEvent = (e) => {
            const ex = e.changedTouches[0].pageX;
            const ey = e.changedTouches[0].pageY;
            updateMouse(e, ex, ey);
            handleClickEvent();
        };

        renderer.domElement.addEventListener("click", handleClickEvent, true);
        renderer.domElement.addEventListener("touchstart", handleTouchStartEvent, true);

        renderer.domElement.addEventListener(
            "pointerdown",
            handlePointerDownEvent,
            true
        );
        renderer.domElement.addEventListener(
            "pointermove",
            handlePointerMoveEvent,
            true
        );
        renderer.domElement.addEventListener(
            "touchmove",
            handleTouchMoveEvent,
            false
        );
        renderer.domElement.addEventListener(
            "pointerup",
            handlePointerUpEvent,
            true
        );
        renderer.domElement.addEventListener(
            "touchend",
            handlePointerUpEvent,
            true
        );
/////////////////////////////////////////////CLICK EVENTS END///////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////RENDER LOOP START//////////////////////////////////////////

        //Add stats.js
        // const stats = new Stats();
        // stats.showPanel(1);
        // 0: fps, 1: ms, 2: mb, 3+: custom
        // document.body.appendChild(stats.dom);
        // stats.dom.style.right = "100px";
        // stats.dom.style.left = "";

        //Setup render loop
        //TODO Maybe consider secondary loop for component updates
        const animate = () => {
            // stats.begin();

            const delta = clock.getDelta();
            controls.controls.update(delta);
            controls.update();
            const cameraHeight = heightmap.update(renderer);
            controls.setCameraHeight(cameraHeight);

            Component.components.forEach((component) => component.update(delta))

            // Update whiteboards
            //todo - make this automatic somehow
            // whiteboardEntrance.update();
            whiteboardEntrance02.update();

            whiteboardGallery00.update();
            whiteboardGallery.update();
            whiteboardGallery02.update();

            // whiteboardSpeaker00.update();
            whiteboardSpeaker.update();
            whiteboardSpeaker02.update();

            whiteboardLungRoom00.update();
            whiteboardLungRoom.update();
            whiteboardLungRoom02.update();

            whiteboardLastRoom00.update();
            whiteboardLastRoom.update();
            whiteboardLastRoom02.update();

            //todo -does this need tidying up?
            if (heightmap.hudEnabled) renderer.autoClear = false;

            // render 3D scene
            renderer.render(scene, camera);

            //todo -does this need tidying up?
            if (heightmap.hudEnabled) {
                // now render HUD (for heightmap) on top
                renderer.clearDepth();
                // renderer.render(hudScene, camera);
                renderer.render(camera);
            }

            // stats.end();
            requestAnimationFrame(animate);
        };
/////////////////////////////////////////////RENDER LOOP END////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////START WINDOW RESIZE////////////////////////////////////////////
        const onWindowResize = () => {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        };

        window.addEventListener("resize", onWindowResize, false);
//////////////////////////////////////////END  WINDOW RESIZE////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////VIDEO START////////////////////////////////////////////
        const videoViewerIdea01 = new VideoViewer(scene, "video-1", "assets/videos/preMortem360p.jpg", .945, .535,);
        videoViewerIdea01.setPosition(8.857,1.764,-1.67);
        videoViewerIdea01.setRotation(0, 180, 0);

        const videoViewerIdea03 = new VideoViewer(scene, "video-3", "assets/videos/positioningPL360.jpg", .94, .53,);
        videoViewerIdea03.setPosition(8.857-1.5,1.764,-1.67);
        videoViewerIdea03.setRotation(0, 180, 0);

        const videoViewerIdea02 = new VideoViewer(scene, "video-2", "assets/videos/positioningTemplates360.jpg", .94, .53,);
        videoViewerIdea02.setPosition(8.857-3,1.764,-1.67);
        videoViewerIdea02.setRotation(0, 180, 0);



        // const videoViewerEntrance = new VideoViewer(scene, "video-1", "assets/videos/Phorix_Website_Edit_Low.jpg", 5.6, 3.2,);
        // videoViewerEntrance.setPosition(-25.7, 2.9, 10.56);
        // videoViewerEntrance.setRotation(0, 90, 0);
/////////////////////////////////////////////////VIDEO END//////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////IMAGEVIEWER START////////////////////////////////////////////
        //todo - maybe make this just scan a folder?
//ENR_PP_01
        const ENR_PP_01 = new ImageViewer(scene, [
            "assets/images/ENR_PP_01/Clinical-Dev-PPT.jpg",
            "assets/images/ENR_PP_01/Clinical-Dev-PPT2.jpg",
            "assets/images/ENR_PP_01/Clinical-Dev-PPT3.jpg",
        ], 3.82, 2.14,);
        ENR_PP_01.setPosition(-5.63, 1.9, 6.93);
        ENR_PP_01.setRotation(0, 0, 0);
//ENR_PP_02
        const ENR_PP_02 = new ImageViewer(scene, [
            "assets/images/ENR_PP_02/az-mainstage1.jpg",
            "assets/images/ENR_PP_02/az-mainstage2.jpg",
            "assets/images/ENR_PP_02/az-mainstage3.jpg",
            "assets/images/ENR_PP_02/az-mainstage4.jpg",
            "assets/images/ENR_PP_02/az-mainstage5.jpg",
            "assets/images/ENR_PP_02/az-mainstage6.jpg",
            "assets/images/ENR_PP_02/az-mainstage7.jpg",
            "assets/images/ENR_PP_02/az-mainstage8.jpg",
            "assets/images/ENR_PP_02/az-mainstage9.jpg",
            "assets/images/ENR_PP_02/az-mainstage10.jpg",
            "assets/images/ENR_PP_02/az-mainstage11.jpg",
            "assets/images/ENR_PP_02/az-mainstage12.jpg",
            "assets/images/ENR_PP_02/az-mainstage13.jpg",
            "assets/images/ENR_PP_02/az-mainstage14.jpg",
            "assets/images/ENR_PP_02/az-mainstage15.jpg",
            "assets/images/ENR_PP_02/az-mainstage16.jpg",
            "assets/images/ENR_PP_02/az-mainstage17.jpg",
            "assets/images/ENR_PP_02/az-mainstage18.jpg",
            "assets/images/ENR_PP_02/az-mainstage19.jpg",
            "assets/images/ENR_PP_02/az-mainstage20.jpg",
            "assets/images/ENR_PP_02/az-mainstage21.jpg",
            "assets/images/ENR_PP_02/az-mainstage22.jpg",
            "assets/images/ENR_PP_02/az-mainstage23.jpg",
            "assets/images/ENR_PP_02/az-mainstage24.jpg",
            "assets/images/ENR_PP_02/az-mainstage25.jpg",
            "assets/images/ENR_PP_02/az-mainstage26.jpg",
            "assets/images/ENR_PP_02/az-mainstage27.jpg",
            "assets/images/ENR_PP_02/az-mainstage28.jpg",
            "assets/images/ENR_PP_02/az-mainstage29.jpg",
            "assets/images/ENR_PP_02/az-mainstage30.jpg",
            "assets/images/ENR_PP_02/az-mainstage31.jpg",
            "assets/images/ENR_PP_02/az-mainstage32.jpg",
            "assets/images/ENR_PP_02/az-mainstage33.jpg",
            "assets/images/ENR_PP_02/az-mainstage34.jpg",
            "assets/images/ENR_PP_02/az-mainstage35.jpg",
            "assets/images/ENR_PP_02/az-mainstage36.jpg",
            "assets/images/ENR_PP_02/az-mainstage37.jpg",
            "assets/images/ENR_PP_02/az-mainstage38.jpg",
            "assets/images/ENR_PP_02/az-mainstage39.jpg",
            "assets/images/ENR_PP_02/az-mainstage40.jpg",
            "assets/images/ENR_PP_02/az-mainstage41.jpg",
            "assets/images/ENR_PP_02/az-mainstage42.jpg",
            "assets/images/ENR_PP_02/az-mainstage43.jpg",
            "assets/images/ENR_PP_02/az-mainstage44.jpg",
            "assets/images/ENR_PP_02/az-mainstage45.jpg",
            "assets/images/ENR_PP_02/az-mainstage46.jpg",
            "assets/images/ENR_PP_02/az-mainstage47.jpg",
            "assets/images/ENR_PP_02/az-mainstage48.jpg",
            "assets/images/ENR_PP_02/az-mainstage49.jpg",
            "assets/images/ENR_PP_02/az-mainstage50.jpg",
            "assets/images/ENR_PP_02/az-mainstage51.jpg",
            "assets/images/ENR_PP_02/az-mainstage52.jpg",
            "assets/images/ENR_PP_02/az-mainstage53.jpg",
            "assets/images/ENR_PP_02/az-mainstage54.jpg",
            "assets/images/ENR_PP_02/az-mainstage55.jpg",
            "assets/images/ENR_PP_02/az-mainstage56.jpg",
            "assets/images/ENR_PP_02/az-mainstage57.jpg",
            "assets/images/ENR_PP_02/az-mainstage58.jpg",
            "assets/images/ENR_PP_02/az-mainstage59.jpg",
            "assets/images/ENR_PP_02/az-mainstage60.jpg",
            "assets/images/ENR_PP_02/az-mainstage61.jpg",
            "assets/images/ENR_PP_02/az-mainstage62.jpg",
            "assets/images/ENR_PP_02/az-mainstage63.jpg",
            "assets/images/ENR_PP_02/az-mainstage64.jpg",
            "assets/images/ENR_PP_02/az-mainstage65.jpg",
            "assets/images/ENR_PP_02/az-mainstage66.jpg",
            "assets/images/ENR_PP_02/az-mainstage67.jpg",
            "assets/images/ENR_PP_02/az-mainstage68.jpg",
            "assets/images/ENR_PP_02/az-mainstage69.jpg",
            "assets/images/ENR_PP_02/az-mainstage70.jpg",
            "assets/images/ENR_PP_02/az-mainstage71.jpg",
            "assets/images/ENR_PP_02/az-mainstage72.jpg",
            "assets/images/ENR_PP_02/az-mainstage73.jpg",
            "assets/images/ENR_PP_02/az-mainstage74.jpg",
            "assets/images/ENR_PP_02/az-mainstage75.jpg",
            "assets/images/ENR_PP_02/az-mainstage76.jpg",
            "assets/images/ENR_PP_02/az-mainstage77.jpg",
            "assets/images/ENR_PP_02/az-mainstage78.jpg",
            "assets/images/ENR_PP_02/az-mainstage79.jpg",
            "assets/images/ENR_PP_02/az-mainstage80.jpg",
            "assets/images/ENR_PP_02/az-mainstage81.jpg",
            "assets/images/ENR_PP_02/az-mainstage82.jpg",
            "assets/images/ENR_PP_02/az-mainstage83.jpg",
            "assets/images/ENR_PP_02/az-mainstage84.jpg",
            "assets/images/ENR_PP_02/az-mainstage85.jpg",
            "assets/images/ENR_PP_02/az-mainstage86.jpg",
            "assets/images/ENR_PP_02/az-mainstage87.jpg",
            "assets/images/ENR_PP_02/az-mainstage88.jpg",
            "assets/images/ENR_PP_02/az-mainstage89.jpg",
            "assets/images/ENR_PP_02/az-mainstage90.jpg",
            "assets/images/ENR_PP_02/az-mainstage91.jpg",
            "assets/images/ENR_PP_02/az-mainstage92.jpg",
            "assets/images/ENR_PP_02/az-mainstage93.jpg",
            "assets/images/ENR_PP_02/az-mainstage94.jpg",
            "assets/images/ENR_PP_02/az-mainstage95.jpg",
            "assets/images/ENR_PP_02/az-mainstage96.jpg",
            "assets/images/ENR_PP_02/az-mainstage97.jpg",
            "assets/images/ENR_PP_02/az-mainstage98.jpg",
            "assets/images/ENR_PP_02/az-mainstage99.jpg",
            "assets/images/ENR_PP_02/az-mainstage100.jpg",
            "assets/images/ENR_PP_02/az-mainstage101.jpg",
            "assets/images/ENR_PP_02/az-mainstage102.jpg",
            "assets/images/ENR_PP_02/az-mainstage103.jpg",
            "assets/images/ENR_PP_02/az-mainstage104.jpg",
            "assets/images/ENR_PP_02/az-mainstage105.jpg",
            "assets/images/ENR_PP_02/az-mainstage106.jpg",
        ], 5.6, 3.15,);
        ENR_PP_02.setPosition(-25.65, 2.84, 10.58);
        ENR_PP_02.setRotation(0, 90, 0);
//GLR_PP_01
        const GLR_PP_01 = new ImageViewer(scene, [
            "assets/images/GAL_PP_01/GROUP-presentation18.jpg",
            "assets/images/GAL_PP_01/GROUP-presentation19.jpg",
            "assets/images/GAL_PP_01/GROUP-presentation20.jpg",
            "assets/images/GAL_PP_01/GROUP-presentation21.jpg",
            "assets/images/GAL_PP_01/GROUP-presentation22.jpg",
            "assets/images/GAL_PP_01/GROUP-presentation23.jpg",
            "assets/images/GAL_PP_01/GROUP-presentation24.jpg",
            "assets/images/GAL_PP_01/GROUP-presentation25.jpg",
            "assets/images/GAL_PP_01/GROUP-presentation26.jpg",
            "assets/images/GAL_PP_01/GROUP-presentation27.jpg",
            "assets/images/GAL_PP_01/GROUP-presentation28.jpg",
            "assets/images/GAL_PP_01/GROUP-presentation29.jpg",
            "assets/images/GAL_PP_01/GROUP-presentation30.jpg",
            "assets/images/GAL_PP_01/GROUP-presentation31.jpg",
            "assets/images/GAL_PP_01/GROUP-presentation32.jpg",
        ], 3.2, 1.8,);
        GLR_PP_01.setPosition(0.0, 1.3+.35, 12.59);//****
        GLR_PP_01.setRotation(0, 180, 0);
//SPR_PP_01
        const SPR_PP_01 = new ImageViewer(scene, [
            "assets/images/SPK_PP_02/GROUP-presentation.jpg",
            "assets/images/SPK_PP_02/GROUP-presentation2.jpg",
            "assets/images/SPK_PP_02/GROUP-presentation3.jpg",
            "assets/images/SPK_PP_02/GROUP-presentation4.jpg",
            "assets/images/SPK_PP_02/GROUP-presentation5.jpg",
            "assets/images/SPK_PP_02/GROUP-presentation6.jpg",
            "assets/images/SPK_PP_02/GROUP-presentation7.jpg",
            "assets/images/SPK_PP_02/GROUP-presentation8.jpg",
            "assets/images/SPK_PP_02/GROUP-presentation9.jpg",
            "assets/images/SPK_PP_02/GROUP-presentation10.jpg",
            "assets/images/SPK_PP_02/GROUP-presentation11.jpg",
            "assets/images/SPK_PP_02/GROUP-presentation12.jpg",
            "assets/images/SPK_PP_02/GROUP-presentation13.jpg",
            "assets/images/SPK_PP_02/GROUP-presentation14.jpg",
            "assets/images/SPK_PP_02/GROUP-presentation15.jpg",
            "assets/images/SPK_PP_02/GROUP-presentation16.jpg",
            "assets/images/SPK_PP_02/GROUP-presentation17.jpg",
        ], 5.85, 2.925,);
        SPR_PP_01.setPosition(-7.95, 2.15, 4.45);
        SPR_PP_01.setRotation(0, 180, 0);
//LUR_PP_01
        const LUR_PP_01 = new ImageViewer(scene, [
            "assets/images/LUN_PP_01/GROUP-presentation18.jpg",
            "assets/images/LUN_PP_01/GROUP-presentation19.jpg",
            "assets/images/LUN_PP_01/GROUP-presentation20.jpg",
            "assets/images/LUN_PP_01/GROUP-presentation21.jpg",
            "assets/images/LUN_PP_01/GROUP-presentation22.jpg",
            "assets/images/LUN_PP_01/GROUP-presentation23.jpg",
            "assets/images/LUN_PP_01/GROUP-presentation24.jpg",
            "assets/images/LUN_PP_01/GROUP-presentation25.jpg",
            "assets/images/LUN_PP_01/GROUP-presentation26.jpg",
            "assets/images/LUN_PP_01/GROUP-presentation27.jpg",
            "assets/images/LUN_PP_01/GROUP-presentation28.jpg",
            "assets/images/LUN_PP_01/GROUP-presentation29.jpg",
            "assets/images/LUN_PP_01/GROUP-presentation30.jpg",
            "assets/images/LUN_PP_01/GROUP-presentation31.jpg",
            "assets/images/LUN_PP_01/GROUP-presentation32.jpg",
        ], 5.6, 3.15,);
        LUR_PP_01.setPosition(-31.68, 2.56, -1.63);
        LUR_PP_01.setRotation(0, 90, 0);
//LAR_PP_01
        const LAR_PP_01 = new ImageViewer(scene, [
            "assets/images/LAS_PP_01/GROUP-presentation33.jpg",
            "assets/images/LAS_PP_01/GROUP-presentation34.jpg",
            "assets/images/LAS_PP_01/GROUP-presentation35.jpg",
            "assets/images/LAS_PP_01/GROUP-presentation36.jpg",
            "assets/images/LAS_PP_01/GROUP-presentation37.jpg",
            "assets/images/LAS_PP_01/GROUP-presentation38.jpg",
            "assets/images/LAS_PP_01/GROUP-presentation39.jpg",
            "assets/images/LAS_PP_01/GROUP-presentation40.jpg",
            "assets/images/LAS_PP_01/GROUP-presentation41.jpg",
            "assets/images/LAS_PP_01/GROUP-presentation42.jpg",
            "assets/images/LAS_PP_01/GROUP-presentation43.jpg",
            "assets/images/LAS_PP_01/GROUP-presentation44.jpg",
        ], 3.6, 1.8,);
        LAR_PP_01.setPosition(-39.63, 3.6, 22.35);
        LAR_PP_01.setRotation(0, 118.75, 0);
///////////////////////////////////////////IMAGEVIEWER END//////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////

        //Todo - Chris to make map navigation system
        // const clickableMap = new ClickableMap(scene, [
        //   "assets/images/GuildTV Phorix ZoneVS LG-01.jpg",
        //   "assets/images/GuildTV Phorix ZoneVS LG-16.jpg",
        // ]);
        // clickableMap.setPosition(-.5, 1.5, -5.4);

////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////NEEDS LABEL***////////////////////////////////////////////////
        //calls the render loop
        animate();

        //cleanup / destroy
        return () => {
            window.removeEventListener("resize", onWindowResize);
            renderer.domElement.removeEventListener("click", handleClickEvent);
            renderer.domElement.removeEventListener("touchstart", handleClickEvent);

            renderer.domElement.removeEventListener(
                "pointerdown",
                handlePointerDownEvent
            );
            renderer.domElement.removeEventListener(
                "pointermove",
                handlePointerMoveEvent
            );
            renderer.domElement.removeEventListener(
                "touchmove",
                handleTouchMoveEvent
            );
            renderer.domElement.removeEventListener(
                "pointerup",
                handlePointerUpEvent
            );
        };
    }, []);
///////////////////////////////////////////end of Thing to hook into React??///////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////WHITEBOARD INTERFACE START////////////////////////////////////////////
    const [textFieldOpen, setTextFieldOpen] = useState(false);
    const [textFieldValue, setTextFieldValue] = useState("");

    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [imageModalOpen_01a, setImageModalOpen_01a] = useState(false);
    const [imageModalOpen_01b, setImageModalOpen_01b] = useState(false);
    const [imageModalOpen_02a, setImageModalOpen_02a] = useState(false);
    const [imageModalOpen_02b, setImageModalOpen_02b] = useState(false);
    const [imageModalOpen_04a, setImageModalOpen_04a] = useState(false);
    const [imageModalOpen_04b, setImageModalOpen_04b] = useState(false);
    const [imageModalOpen_06b, setImageModalOpen_06b] = useState(false);
    const [imageModalOpen_08a, setImageModalOpen_08a] = useState(false);
    const [imageModalOpen_08b, setImageModalOpen_08b] = useState(false);


    const [welcomeModalOpen, setWelcomeModalOpen] = useState(false);

    const onChangeTextField = (value) => {
        setTextFieldValue(value);
    };

    const onConfirmTextField = () => {
        setTextFieldOpen(false);
        setTextFieldValue("");
        whiteboard?.setCurrentTextValue(textFieldValue);
    };

    const onCancelTextField = () => {
        setTextFieldOpen(false);
        setTextFieldValue("");
        whiteboard?.deleteCurrentTextField();
        whiteboard?.enableDrawingMode();
    };
//////////////////////////////////WHITEBOARD INTERFACE END//////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////DETECT INCOMPATIBLE BROWSERS//////////////////////////////////////////
    const [incompatibleOpen, setIncompatibleOpen] = useState(false);

    useEffect(() => {
        setIncompatibleOpen(!IsBrowserCompatible());
    }, [])
/////////////////////////////DETECT INCOMPATIBLE BROWSERS END///////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////USER INTERFACE START////////////////////////////////////////////
//  In here are also various things that make the other things work - like modals / textfields
    return (
        <>
            <video id="video-1" loop crossOrigin="anonymous" playsInline style={{display: "none"}}>
                <source src="assets/videos/preMortem360p.mp4"
                        type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
            </video>
            <video id="video-2" loop crossOrigin="anonymous" playsInline style={{display: "none"}}>
                <source src="assets/videos/positioningTemplates360.mp4"
                        type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
            </video>
            <video id="video-3" loop crossOrigin="anonymous" playsInline style={{display: "none"}}>
                <source src="assets/videos/positioningPl360p.mp4"
                        type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
            </video>
            <TextFieldDialog
                open={textFieldOpen}
                value={textFieldValue}
                onChange={onChangeTextField}
                onClose={onConfirmTextField}
                onCancel={onCancelTextField}
            />
            <ImageModal
                id="popup-contact"
                open={imageModalOpen}
                onClose={() => setImageModalOpen(false)}
                src="assets/images/ENR_P0_01/ENTRANCE-1.jpg"
            />
            <ImageModal_01
                id="popup-contact_01"
                open={imageModalOpen_01a}
                onClose={() => setImageModalOpen_01a(false)}//yes
                src="assets/images/HAL_PO_01/HALLWAY-1a.jpg"
            />
            <ImageModal_02
                id="popup-contact_02"
                open={imageModalOpen_01b}
                onClose={() => setImageModalOpen_01b(false)}//yes
                src="assets/images/HAL_PO_01/HALLWAY-1b.jpg"
            />
            <ImageModal_03
                id="popup-contact_03"
                open={imageModalOpen_02a}
                onClose={() => setImageModalOpen_02a(false)}
                src="assets/images/HAL_PO_01/HALLWAY-2a.jpg"
            />
            <ImageModal_04
                id="popup-contact_04"
                open={imageModalOpen_02b}
                onClose={() => setImageModalOpen_02b(false)}
                src="assets/images/HAL_PO_01/HALLWAY-2b.jpg"
            />
            <ImageModal_05
                id="popup-contact_05"
                open={imageModalOpen_04a}
                onClose={() => setImageModalOpen_04a(false)}//==4a//=azgold
                src="assets/images/HAL_PO_01/HALLWAY-4a.jpg"
            />
            <ImageModal_06
                id="popup-contact_06"
                open={imageModalOpen_04b}
                onClose={() => setImageModalOpen_04b(false)}//==4b
                src="assets/images/HAL_PO_01/HALLWAY-4b.jpg"
            />
            <ImageModal_05
                id="popup-contact_05"
                open={imageModalOpen_06b}
                onClose={() => setImageModalOpen_06b(false)}//==4a//=azgold
                src="assets/images/HAL_PO_01/HALLWAY-6b.jpg"
            />
            <ImageModal_06
                id="popup-contact_06"
                open={imageModalOpen_08a}
                onClose={() => setImageModalOpen_08a(false)}//==4b
                src="assets/images/HAL_PO_01/HALLWAY-8a.jpg"
            />
            <ImageModal_07
                id="popup-contact_07"
                open={imageModalOpen_08b}
                onClose={() => setImageModalOpen_08b(false)}//==6b
                src="assets/images/HAL_PO_01/HALLWAY-8b.jpg"
            />

            <Dialog open={incompatibleOpen}>
                <DialogActions>
                    <Button onClick={() => setIncompatibleOpen(false)}>Close X</Button>
                </DialogActions>

                <DialogTitle>You are using an Incompatible Browser</DialogTitle>
                <DialogContent>
                    To enjoy this site at it's best please use a chrome based browser.
                </DialogContent>

                <DialogContent>
                    Using your current browser may result in a poor viewing experience.
                </DialogContent>

                <DialogContent>
                </DialogContent>
                <DialogContent>
                </DialogContent>
                <DialogContent>
                </DialogContent>
            </Dialog>

            {/* Loading screen  put cool spinner here*/}
            {showLoadingScreen && (
                <Box
                    width="100%"
                    height="100%"
                    position="absolute"
                    bgcolor="#fbfbfb"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    zIndex={200}
                >
                    {/*<Typography variant="h3">Loading...</Typography>*/}
                    <img src="assets/images/az-loading.gif"/>
                </Box>
            )}

            <Box position="absolute" left={0} top={0} p={2} zIndex={100}>
                <IconButton onClick={() => setMenuOpen(true)}>
                    <MenuIcon/>
                </IconButton>
            </Box>

            <Drawer open={menuOpen} onClose={() => setMenuOpen(false)}>
                <Box minWidth={300}>
                    <List component="nav" aria-label="main mailbox folders">
                        <ListItem button>
                            <ListItemIcon>
                                <HelpIcon />
                            </ListItemIcon>
                            <ListItemText primary="Help" />
                        </ListItem>
                        <ListItem button>
                            <ListItemIcon>
                                <InfoIcon />
                            </ListItemIcon>
                            <ListItemText primary="About" />
                        </ListItem>
                        {/*<ListItem button>*/}
                        {/*    <ListItemIcon>*/}
                        {/*        <GavelIcon />*/}
                        {/*    </ListItemIcon>*/}
                        {/*    <ListItemText primary="Legal" />*/}
                        {/*</ListItem>*/}
                    </List>
                </Box>
            </Drawer>

            <ToolBar
                params={{
                    onSkipPrev: () => {
                        const index = MovePad.heroPads.indexOf(MovePad.currentPad);
                        if (index === 0) {
                            const nextPad = MovePad.heroPads[MovePad.heroPads.length - 1];
                            MovePad.currentPad = nextPad;
                            controls.moveTo(new THREE.Vector3(nextPad.position.x, nextPad.position.y + 1.6, nextPad.position.z));
                        } else {
                            const nextPad = MovePad.heroPads[index - 1];
                            MovePad.currentPad = nextPad;
                            if (nextPad.hero==true)
                            {
                                console.log(`DEBUG moving directly to a HERO pad = ${nextPad.hero}`);
                                controls.moveTo(new THREE.Vector3(nextPad.position.x, nextPad.position.y + 1.6, nextPad.position.z));
                            }
                            else if (nextPad.hero==false)
                            {
                                console.log(`DEBUG skip through basic pads and move to next HERO pad = ${nextPad.hero}`);
                                console.log(`DEBUG current pad index is = ${index}`);
                                // const skipToNextPad = MovePad.pads[index - 1];
                                // MovePad.currentPad = skipToNextPad;
                                // controls.moveTo(new THREE.Vector3(skipToNextPad.position.x, skipToNextPad.position.y + 1.6, skipToNextPad.position.z));
                            }
                        }
                    },
                    onSkipNext: () => {
                        const index = MovePad.heroPads.indexOf(MovePad.currentPad);


                        if (index === MovePad.heroPads.length - 1) {
                            const nextPad = MovePad.heroPads[0];
                            MovePad.currentPad = nextPad;
                            controls.moveTo(new THREE.Vector3(nextPad.position.x, nextPad.position.y + 1.6, nextPad.position.z));
                        }
                        else{
                            const nextPad = MovePad.heroPads[index + 1];
                            MovePad.currentPad = nextPad;
                            if (nextPad.hero==true)
                            {
                                console.log(`DEBUG moving directly to a HERO pad = ${nextPad.hero}`);
                                controls.moveTo(new THREE.Vector3(nextPad.position.x, nextPad.position.y + 1.6, nextPad.position.z));
                            }
                            else if (nextPad.hero==false)
                            {
                                console.log(`DEBUG skip through basic pads and move to next HERO pad = ${nextPad.hero}`);
                                console.log(`DEBUG current pad index is = ${index}`);
                                // const skipToNextPad = MovePad.pads[index + 1];
                                // MovePad.currentPad = skipToNextPad;
                                // controls.moveTo(new THREE.Vector3(skipToNextPad.position.x, skipToNextPad.position.y + 1.6, skipToNextPad.position.z));
                            }
                        }
                    },
                    onJumpLeft: () => controls.jumpLeft(),
                    onJumpRight: () => controls.jumpRight(),
                    onJumpForward: () => controls.jumpForward(),
                    onJumpBackward: () => controls.jumpBackward(),
                    onGoHome: () => controls.resetPosition(),
                    onResetPositionHost01: () => controls.resetPositionHost01(),
                    onResetPositionHost02: () => controls.resetPositionHost02(),
                    onResetPositionHost03: () => controls.resetPositionHost03(),
                    onFullScreen: () => handle.enter(),
                }}
            />

            {/*BEGIN FULLSCREEN CONTENT - NO IDEA WHERE THIS GOES - is escape message in right place?*/}
            <FullScreen handle={handle}>
                {/*END  FULLSCREEN CONTENT*/}
                <div ref={mountRef}/>
            </FullScreen>
        </>
////////////////////////////////////////USER INTERFACE END//////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////
    );
};

export default BasicThreeExample;
//////////////////////////////////////////////END MAIN THREE.JS////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////