import * as THREE from 'three';
import Draggable from './Draggable';
import Hoverable from "./Hoverable";

export default class PostItNote {
    constructor(scene, x, y, z, r, src, planeId, r2) {
        this.loader = new THREE.TextureLoader();

        const geometry = new THREE.PlaneBufferGeometry(0.2, 0.2);
        const material = new THREE.MeshBasicMaterial({color: "white"});

        const board = new THREE.Mesh(geometry, material);
        this.board = board;
        scene.add(this.board);

        this.board.position.set(x, y, z);
        this.board.rotateZ(r);
        if (r2) {
            this.board.rotateY(r2 * THREE.Math.DEG2RAD);
        }

        this.loader.load(src,
            (texture) => {
                // const material = new THREE.MeshBasicMaterial({map: texture});
                const material = new THREE.ShaderMaterial({
                    fragmentShader: this.fragmentShader(),
                    vertexShader: this.vertexShader(),
                    uniforms: {tex: {type: 't', value: texture}}
                });
                this.board.material = material;
                this.board.material.needsUpdate = true;
            }
        );

        new Draggable(board, (_, data) => {
            // this.board.position.set(newPos.x, newPos.y, this.board.position.z);
            const d = 0.05;
            const n = new THREE.Vector3(0, 0, 1);
            const e = new THREE.Euler(data.r.x, data.r.y, data.r.z);
            n.applyEuler(e);

            this.board.position.set(
                data.newPos.x + n.x * d,
                data.newPos.y + n.y * d,
                data.newPos.z + n.z * d
            );
        }, {planeId});

        new Hoverable(this.board, () => {
            document.body.style.cursor = 'move';
        });
    }

    vertexShader() {
        return `
            varying vec2 vUv;

            void main() {
                vUv = uv;

                vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
                gl_Position = projectionMatrix * modelViewPosition;
            }
        `
    }

    fragmentShader() {
        return `
            uniform sampler2D tex;
            varying vec2 vUv;

            void main() {
                gl_FragColor = texture2D(tex, vUv);
            }
        `
    }
}
