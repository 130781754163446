import * as THREE from 'three';

export default class UnlitImageMaterial extends THREE.MeshBasicMaterial {
    constructor(opts) {
        super({color: "white"});
        this.obj = null;
        this.loader = new THREE.TextureLoader();

        this.loader.load(opts.src,
            (texture) => {
                this.obj.material = new THREE.ShaderMaterial({
                    fragmentShader: this.fragmentShader(),
                    vertexShader: this.vertexShader(),
                    uniforms: {tex: {type: 't', value: texture}}
                });
                this.obj.material.needsUpdate = true;
            }
        );
    }

    vertexShader() {
        return `
            varying vec2 vUv;

            void main() {
                vUv = uv;

                vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
                gl_Position = projectionMatrix * modelViewPosition;
            }
        `
    }

    fragmentShader() {
        return `
            uniform sampler2D tex;
            varying vec2 vUv;

            void main() {
                gl_FragColor = texture2D(tex, vUv);
            }
        `
    }
}
