import * as THREE from "three";
import * as holdEvent from "hold-event";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import MovePad from "./MovePad";

// const EPS = 1e-5;
const EPS = 0.02;
const raycaster = new THREE.Raycaster();

export default class LookControlsV2 {

    lerpAlpha = 0.03;
    m_enabled = true;

    constructor(camera, renderer, scene,) {

        this.scene = scene;

        this.cameraHeight = 1.6;

        // in order to archive FPS look, set EPSILON for the distance to the center
        camera.position.set(0, this.cameraHeight, EPS);

        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        this.controls = controls;
        controls.target = new THREE.Vector3(0, this.cameraHeight, 0)
        controls.update();

        const KEYCODE = {
            W: 87,
            A: 65,
            S: 83,
            D: 68,
            ARROW_LEFT: 37,
            ARROW_UP: 38,
            ARROW_RIGHT: 39,
            ARROW_DOWN: 40,
        };

        const moveSpeed = 0.0025;
        const delay = 10;

        //The keyboard movement is jumpy - but pad is smooth so we can improve this
        const wKey = new holdEvent.KeyboardKeyHold(KEYCODE.W, delay);
        const aKey = new holdEvent.KeyboardKeyHold(KEYCODE.A, delay);
        const sKey = new holdEvent.KeyboardKeyHold(KEYCODE.S, delay);
        const dKey = new holdEvent.KeyboardKeyHold(KEYCODE.D, delay);
        aKey.addEventListener('holding', (event) => {
            this.moveLeft(moveSpeed * event.deltaTime)
        });
        dKey.addEventListener('holding', (event) => {
            this.moveRight(moveSpeed * event.deltaTime)
        });
        wKey.addEventListener('holding', (event) => {
            this.moveForward(moveSpeed * event.deltaTime)
        });
        sKey.addEventListener('holding', (event) => {
            this.moveBackward(moveSpeed * event.deltaTime)
        });

        const leftKey = new holdEvent.KeyboardKeyHold(KEYCODE.ARROW_LEFT, delay);
        const rightKey = new holdEvent.KeyboardKeyHold(KEYCODE.ARROW_RIGHT, delay);
        const upKey = new holdEvent.KeyboardKeyHold(KEYCODE.ARROW_UP, delay);
        const downKey = new holdEvent.KeyboardKeyHold(KEYCODE.ARROW_DOWN, delay);
        leftKey.addEventListener('holding', (event) => {
            this.moveLeft(moveSpeed * event.deltaTime)
        });
        rightKey.addEventListener('holding', (event) => {
            this.moveRight(moveSpeed * event.deltaTime)
        });
        upKey.addEventListener('holding', (event) => {
            this.moveForward(moveSpeed * event.deltaTime)
        });
        downKey.addEventListener('holding', (event) => {
            this.moveBackward(moveSpeed * event.deltaTime)
        });

        this.collisionObjects = this.scene.children;

        this.controls = controls;
        this.camera = camera;
    }

    setCameraHeight(height) {
        this.cameraHeight = height;
    }

    moveForward(speed) {
        if (!this.enabled) return;
        const direction = this.controls.target.clone().sub(this.camera.position.clone());
        direction.projectOnPlane(new THREE.Vector3(0, 1, 0))
        this.move(direction, speed)
    }

    moveLeft(speed) {
        if (!this.enabled) return;
        const direction = this.controls.target.clone().sub(this.camera.position.clone());
        const axis = new THREE.Vector3(0, 1, 0);
        const angle = Math.PI / 2;
        direction.applyAxisAngle(axis, angle);
        direction.projectOnPlane(new THREE.Vector3(0, 1, 0))
        this.move(direction, speed)
    }

    moveRight(speed) {
        if (!this.enabled) return;
        const direction = this.controls.target.clone().sub(this.camera.position.clone());
        const axis = new THREE.Vector3(0, 1, 0);
        const angle = -Math.PI / 2;
        direction.applyAxisAngle(axis, angle);
        direction.projectOnPlane(new THREE.Vector3(0, 1, 0))
        this.move(direction, speed)
    }

    moveBackward(speed) {
        if (!this.enabled) return;
        const direction = this.controls.target.clone().sub(this.camera.position.clone());
        direction.projectOnPlane(new THREE.Vector3(0, 1, 0))
        this.move(direction.negate(), speed)
    }

    move(direction, speed) {
        if (!this.enabled) return;

        this.targetPosition = undefined;

        const rayPosition = new THREE.Vector3(this.camera.position.x, this.camera.position.y - 0.5, this.camera.position.z);
        raycaster.set(rayPosition, direction);
        raycaster.camera = this.camera;
        const intersects = raycaster.intersectObjects(this.collisionObjects, true);
        if (intersects.length > 0 && intersects[0].distance < 0.25) return;

        const newTargetPos = new THREE.Vector3();
        const newCameraPos = new THREE.Vector3();
        newCameraPos.addVectors(direction.normalize().multiplyScalar(speed), this.camera.position);
        newTargetPos.addVectors(direction.normalize().multiplyScalar(speed), this.controls.target);

        this.controls.target.set(newTargetPos.x, newTargetPos.y + (this.cameraHeight - newCameraPos.y), newTargetPos.z);
        this.camera.position.set(newCameraPos.x, this.cameraHeight, newCameraPos.z);
        this.controls.update();
        if (this.postMove) this.postMove(newCameraPos, this.camera.quaternion);
    }

    //I wanted to see if I could pass a parameter to moveto to tell it what viewpoint to choose but it's not needed
    // moveTo(position, rotation, myParameter) {
    moveTo(position, rotation, rotateToViewSouth, rotateToViewEast, rotateToViewWest ) {
        if (!this.enabled) return;

            // this.targetPosition = position;
            // this.targetRotation = rotation;

        if (   (Math.abs(this.camera.position.z-position.z)  ) <=   (Math.abs(this.camera.position.x-position.x)  )    ) {
            // console.log(`DEBUG we are going X `);
            if ( this.camera.position.x<=position.x)
            {
                // console.log(`DEBUG we are going EAST `);
                    this.targetPosition = position;
                    this.targetRotation = rotateToViewEast;
            }
            else
            {
                // console.log(`DEBUG we are going WEST `);
                    this.targetPosition = position;
                    this.targetRotation = rotateToViewWest;
            }
        }
        else   {
            // console.log(`DEBUG we are going Z `);
            if ( this.camera.position.z>=position.z)
            {
                // console.log(`DEBUG we are going NORTH `);
                this.targetPosition = position;
                this.targetRotation = rotation;
            }
            else
            {
                // console.log(`DEBUG we are going SOUTH `);
                    this.targetPosition = position;
                    this.targetRotation = rotateToViewSouth;
            }
        }
    }

    setCollisionObjects = (objects) => {
        this.collisionObjects = objects;
    }

    resetPosition() {
        // this.targetPosition = new THREE.Vector3(0, 1.6,  0)
        this.targetPosition = new THREE.Vector3(-15, 1.6, 18.1)
        this.targetRotation = new THREE.Quaternion(0.003406048301625682,-0.0035538097478899494,0.000012104594309447247,0.9999878844889679);
    }

    resetPositionHost01() {
        this.targetPosition = new THREE.Vector3(    -7.524578464968557,1.6130131284818081,-2.078013902273455)
        this.targetRotation = new THREE.Quaternion(-0.015102928657826245,0.9777665923840184,0.07574707498230715,0.1949532584886081);
    }

    resetPositionHost02() {
        this.targetPosition = new THREE.Vector3(-26.26324745193375,1.6000000002734034,-4.794067615543598)
        this.targetRotation = new THREE.Quaternion(-0.0016218888346726217,0.7620893677949652,0.0019090150230850708,0.6474670035083795);
    }

    resetPositionHost03() {
        this.targetPosition = new THREE.Vector3(-38.20820899129713, 3.3, 15.808441853177843)
        this.targetRotation =    new THREE.Quaternion(-0.011927200108533344,0.7119838742628775,0.012096996585899305,0.7019902900758636);
    }

    jumpLeft() {
        const direction = this.controls.target.clone().sub(this.camera.position.clone());
        const axis = new THREE.Vector3(0, 1, 0);
        const angle = Math.PI / 2;
        direction.applyAxisAngle(axis, angle);
        direction.projectOnPlane(new THREE.Vector3(0, 1, 0))
        const newTargetPos = new THREE.Vector3();
        newTargetPos.addVectors(direction.normalize().multiplyScalar(3), this.camera.position);
        this.targetPosition = newTargetPos;
        this.targetRotation = null;
    }

    jumpForward() {
        const direction = this.controls.target.clone().sub(this.camera.position.clone());
        direction.projectOnPlane(new THREE.Vector3(0, 1, 0))
        const newTargetPos = new THREE.Vector3();
        newTargetPos.addVectors(direction.normalize().multiplyScalar(3), this.camera.position);
        this.targetPosition = newTargetPos;
        this.targetRotation = null;
    }

    jumpBackward() {
        const direction = this.controls.target.clone().sub(this.camera.position.clone());
        direction.projectOnPlane(new THREE.Vector3(0, 1, 0))
        direction.negate();
        const newTargetPos = new THREE.Vector3();
        newTargetPos.addVectors(direction.normalize().multiplyScalar(3), this.camera.position);
        this.targetPosition = newTargetPos;
        this.targetRotation = null;
    }

    jumpRight() {
        const direction = this.controls.target.clone().sub(this.camera.position.clone());
        const axis = new THREE.Vector3(0, 1, 0);
        const angle = Math.PI / 2;
        direction.applyAxisAngle(axis, angle);
        direction.projectOnPlane(new THREE.Vector3(0, 1, 0))
        const newTargetPos = new THREE.Vector3();
        newTargetPos.addVectors(direction.normalize().multiplyScalar(-3), this.camera.position);
        this.targetPosition = newTargetPos;
        this.targetRotation = null;
    }

    hideRoom() {
        //does not work
        this.scene.room.visible = false;
    }

    update() {
        if (this.targetPosition) {
            if (this.controls.target.distanceTo(this.targetPosition) <= 0.2) {
                console.log("DEBUG Target reached.")
                this.targetPosition = undefined;
            } else {
                let cameraDirection = this.camera.position.clone().sub(this.controls.target.clone()).normalize()

                if (this.targetRotation) {
                    cameraDirection = new THREE.Vector3(0, 0, 1);
                    cameraDirection.applyQuaternion(this.targetRotation)
                }

                // We calculate a position offset from the real target
                // It must be offset in the direction of the (camera - orbit target) so that the camera never gets too close to the target.
                // Not doing this locks the camera orbit.
                // We do this every frame to ensure a smooth transition.
                const cameraTargetPosition = new THREE.Vector3();
                cameraTargetPosition.addVectors(cameraDirection.multiplyScalar(EPS), this.targetPosition);

                this.controls.target.lerp(this.targetPosition, this.lerpAlpha)
                this.camera.position.lerp(cameraTargetPosition, this.lerpAlpha)
            }
        }
        this.controls.maxDistance = EPS;
    }

    get enabled() {
        return this.m_enabled;
    }

    set enabled(state) {
        this.m_enabled = state;
        this.controls.enabled = state;
    }
}