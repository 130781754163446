import {Box, Button, IconButton, Paper, Container, Hidden} from "@material-ui/core";

import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import AppsIcon from '@material-ui/icons/Apps';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import HomeIcon from '@material-ui/icons/Home';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import RepeatOne from '@material-ui/icons/RepeatOne';
import ExposurePlus2 from '@material-ui/icons/ExposurePlus2';
import Timer3 from '@material-ui/icons/Timer3';

import React from "react";
import Screenshot from "../three-components/Screenshot";
import NumberOneIcon from "../custom-icons/NumberOneIcon";
import NumberTwoIcon from "../custom-icons/NumberTwoIcon";
import NumberThreeIcon from "../custom-icons/NumberThreeIcon";
export default function ToolBar(props) {

    const {params} = props;
    const {
        onSkipPrev,
        onSkipNext,
        onJumpLeft,
        onJumpRight,
        onJumpForward,
        onJumpBackward,
        onGoHome,
        onResetPositionHost01,
        onResetPositionHost02,
        onResetPositionHost03,
        onFullScreen
    } = params;

    return (
        <Box
            position="absolute"
            bottom="2%"
            width="100%"
        >
            <Container maxWidth="sm">
                <Box
                    component={Paper}
                    p={0.5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Hidden xsDown>
                        <IconButton onClick={onSkipPrev}>
                            <SkipPreviousIcon/>
                        </IconButton>
                    </Hidden>

                    {/*<IconButton>*/}
                    {/*    <AppsIcon/>*/}
                    {/*</IconButton>*/}

                    <Box flexGrow={1}/>

                    <IconButton onClick={onGoHome}>
                        <HomeIcon/>
                    </IconButton>
                    {/*//chris - chooes a colour for all *****/}
                    <IconButton onClick={onResetPositionHost01} color="primary">
                        <NumberOneIcon/>
                    </IconButton>

                    <IconButton onClick={onResetPositionHost02} color="primary">
                        <NumberTwoIcon/>
                    </IconButton>

                    <IconButton onClick={onResetPositionHost03} color="primary">
                        <NumberThreeIcon/>
                    </IconButton>

                    {/*//speaker group 1 postits*/}

                    {/*<Button  onClick={onResetPositionHost02} variant="outlined" color="primary">*/}
                    {/*    2*/}
                    {/*</Button>*/}
                    {/*//lung room - group2 postits also in gallery*/}

                    {/*<Button  onClick={onResetPositionHost03} variant="outlined" color="primary">*/}
                    {/*    3*/}
                    {/*</Button>*/}
                    {/*//lastroom - change postits here to lastroom postits*/}

                    {/*<IconButton onClick={onResetPositionHost01}>*/}
                    {/*    <RepeatOne/>*/}
                    {/*</IconButton>*/}
                    {/*<IconButton onClick={onResetPositionHost02}>*/}
                    {/*    <ExposurePlus2/>*/}
                    {/*</IconButton>*/}
                    {/*<IconButton onClick={onResetPositionHost03}>*/}
                    {/*    <Timer3/>*/}
                    {/*</IconButton>*/}

                    <IconButton onClick={onJumpLeft}>
                        <KeyboardArrowLeftIcon/>
                    </IconButton>
                    <IconButton onClick={onJumpRight}>
                        <KeyboardArrowRightIcon/>
                    </IconButton>
                    <IconButton onClick={onJumpForward}>
                        <KeyboardArrowUpIcon/>
                    </IconButton>
                    <IconButton onClick={onJumpBackward}>
                        <KeyboardArrowDownIcon/>
                    </IconButton>
                    {/* <IconButton>
                        <AddIcon/>
                    </IconButton>
                    <IconButton>
                        <RemoveIcon/>
                    </IconButton> */}
                    <Box flexGrow={1}/>
                    <Hidden xsDown>
                        <IconButton onClick={onFullScreen}>
                            <FullscreenIcon/>
                        </IconButton>
                    <IconButton onClick={() => Screenshot.takeScreenshot()}>
                        <PhotoCameraIcon/>
                    </IconButton>
                    </Hidden>
                    <IconButton onClick={onSkipNext}>
                        <SkipNextIcon/>
                    </IconButton>
                </Box>
            </Container>

        </Box>
    )
}