import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
} from "@material-ui/core";

export default function TextFieldDialog(props) {
    const {open, value, onChange, onCancel, onClose} = props;

    return (
        <Dialog open={open} maxWidth="xs" fullWidth>
            <DialogContent>
                <TextField
                    variant="outlined"
                    autoFocus
                    fullWidth
                    multiline={true}
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>Create Text</Button>
                <Button></Button>
                <Button></Button>
                <Button></Button>
                <Button onClick={() => onClose(false)}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}
