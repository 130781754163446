import * as THREE from 'three';
import Clickable from "./Clickable";
import UnlitImageMaterial from "./UnlitMaterial";
import Hoverable from "./Hoverable";

export default class ModalTriggerPDF {

    constructor(scene, onClick, src, width) {
        this.scene = scene;

        // const geometry = new THREE.PlaneBufferGeometry(width, width);
        // const material = new UnlitImageMaterial({src});
        // const boardPDF = new THREE.Mesh(geometry, material);
        // material.obj = boardPDF;
        // this.boardPDF = boardPDF;
        // this.scene.add(this.boardPDF);
        // this.boardPDF.rotateY(90 * THREE.Math.DEG2RAD);
        // this.boardPDF.scale.set(.33, .33, .33);
        const handleClick = (clickable) => {
            onClick(true);
        }
        // new Clickable(this.boardPDF, handleClick)
        

        const mySpriteMaterial = new THREE.SpriteMaterial({
            map: new THREE.TextureLoader().load("assets/icons/ZoneVS-pdf.png"),
            transparent: true,
        })

        const objectSpritePDF = new THREE.Sprite(mySpriteMaterial);
        this.objectSpritePDF = objectSpritePDF;
        scene.add(objectSpritePDF);
        objectSpritePDF.scale.set(.40, .40, .40);
        new Clickable(this.objectSpritePDF, handleClick)

        // new Hoverable(this.objectSpritePDF, () => {
        //     document.body.style.cursor = 'pointer';
        // });

        new Hoverable(this.objectSpritePDF,
            () => {
                document.body.style.cursor = 'pointer';
                this.objectSpritePDF.scale.set(.45, .45, .45);
            },
            () => {
                this.objectSpritePDF.scale.set(.40, .40, .40);
            }
        );

    }

    setPosition = (x, y, z) => {

        this.objectSpritePDF.position.set(x, y, z);
    }

    setRotation = (x, y, z) => {
        // this.boardPDF.rotateY(y * THREE.Math.DEG2RAD);

    }

}
