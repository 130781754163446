import * as THREE from 'three';
import Clickable from "./Clickable";
import Hoverable from "./Hoverable";
// import UnlitImageMaterial from "./UnlitMaterial";
//import {RecordEvent} from "../libs/AnalyticsLib";

export default class ImageViewer {

    width = 3.2;
    height = 1.8;
    currentIndex = 0;

    constructor(scene, images, width, height, hideControls) {
        this.scene = scene;
        this.images = images;
        this.width = width || 3.2;
        this.height = height || 1.8;
        const buttonSize = 0.25;

        this.loader = new THREE.TextureLoader();

        const geometry = new THREE.PlaneBufferGeometry(this.width, this.height);
        const material = new THREE.MeshBasicMaterial({color: "white"});

        const board = new THREE.Mesh(geometry, material);
        this.board = board;
        scene.add(this.board);

        if (!hideControls) {
            this.loader.load("assets/icons/ZoneVS-arrow-Wb.png",
                (texture) => {
                    const material = new THREE.MeshBasicMaterial({
                        map: texture
                    });
                    // const geometry = new THREE.PlaneBufferGeometry(buttonSize/2, buttonSize/2);
                    const geometry = new THREE.CircleGeometry(buttonSize / 2, 32);

                    const control = new THREE.Mesh(geometry, material);
                    control.position.x = -this.width / 2 - buttonSize / 2 - .1;
                    // control.position.y = this.height / 2;
                    this.board.add(control);
                    new Clickable(control, this.previous)

                    new Hoverable(control,
                        () => {
                            document.body.style.cursor = 'pointer';
                            control.scale.setScalar(1.2)
                        },
                        () => {
                            control.scale.setScalar(1);
                        }
                    );
                }
            );

            this.loader.load("assets/icons/ZoneVS-arrow-Eb.png",
                (texture) => {
                    const material = new THREE.MeshBasicMaterial({
                        map: texture
                    });
                    // const geometry = new THREE.PlaneBufferGeometry(buttonSize, buttonSize);
                    const geometry = new THREE.CircleGeometry(buttonSize / 2, 32);
                    const control = new THREE.Mesh(geometry, material);
                    control.position.x = this.width / 2 + buttonSize / 2 + .1;
                    // control.position.y = this.height / 2;
                    this.board.add(control);
                    new Clickable(control, this.next)

                    new Hoverable(control,
                        () => {
                            document.body.style.cursor = 'pointer';
                            control.scale.setScalar(1.2)
                        },
                        () => {
                            control.scale.setScalar(1);
                        }
                    );
                }
            );
        }

        if (this.images)
            this.setImage(this.images[0])
    }

    setPosition = (x, y, z) => {
        this.board.position.set(x, y, z);
    }

    setRotation = (x, y, z) => {
        this.board.rotateY(y * THREE.Math.DEG2RAD);
        ;
    }

    setImage(image) {
        this.loader.load(image,
            (texture) => {
                const material = new THREE.ShaderMaterial({
                    fragmentShader: this.fragmentShader(),
                    vertexShader: this.vertexShader(),
                    uniforms: {tex: {type: 't', value: texture}}
                });
                this.board.material = material;
                this.board.material.needsUpdate = true;
            }
        );
    }

    next = () => {
        if (!this.images) return;
        // if(this.currentIndex === this.images.length - 1) return;
        if (this.currentIndex === this.images.length - 1) this.currentIndex = -1;
        this.currentIndex++;
        this.setImage(this.images[this.currentIndex])
    }

    previous = () => {
        if (!this.images) return;
        // if(this.currentIndex === 0) return;
        if (this.currentIndex === 0) this.currentIndex = this.images.length;
        this.currentIndex--;
        this.setImage(this.images[this.currentIndex])
    }

    vertexShader() {
        return `
            varying vec2 vUv;

            void main() {
                vUv = uv;

                vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
                gl_Position = projectionMatrix * modelViewPosition;
            }
        `
    }

    fragmentShader() {
        return `
            uniform sampler2D tex;
            varying vec2 vUv;

            void main() {
                gl_FragColor = texture2D(tex, vUv);
            }
        `
    }
}