export default class Draggable {

    static draggables = new Map();

    constructor(object, onDrag, meta) {
        this.object = object;
        this.onDrag = onDrag;
        this.meta = meta;
        Draggable.draggables.set(object.uuid, this);
    }

    handleDragged(newPos) {
        this.onDrag(this, newPos);
    }

    dispose() {
        delete Draggable.draggables.delete(this.object.uuid);
    }
}
